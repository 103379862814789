/*
 * Copyright (C) 2023 Corsair M360, Inc - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

// withBasicLayout.js
import React from 'react';
import BasicLayout from './BasicLayout';

const withBasicLayout = (WrappedComponent) => {
	return (props) => (
		<BasicLayout>
			<WrappedComponent {...props} />
		</BasicLayout>
	);
};

export default withBasicLayout;
