/*
 * Copyright (C) 2023 Corsair M360, Inc - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import React, {useState, useEffect, useContext} from 'react';
import {Form, Button, Spinner, Row, Tooltip, OverlayTrigger, Col} from 'react-bootstrap';
import {useParams} from 'react-router-dom';
import {Link} from 'react-router-dom';
import utils from "../../../utils/api";
import {useNavigate} from 'react-router-dom';
import {AlertContext} from '../../../components/AlertContext';
import {useBackdrop} from '../../../components/BackdropContext';

function CreateRecord() {
	const [isLoading, setIsLoading] = useState(false); // State for loading
	const [cName, setCatName] = useState('');
	const [order, setOrder] = useState('');
	const [subCatArray, setSubCatArray] = useState([]);
	
	const {id} = useParams(); // Get the id param from the route
	const navigate = useNavigate();
	const {showAlert} = useContext(AlertContext);
	const {setBackdropVisible} = useBackdrop();
	
	useEffect(() => {
		const fetchData = async () => {
			let subsArr = [];
			setIsLoading(true); // Start loading
			try {
				const data = await utils.callApi({
					endpoint: `hmpboapi/category/${id}`
				}, navigate, showAlert, setBackdropVisible);
				if (data) {
					setCatName(data.name);
					setOrder(data.order);
					for (let k in data.sub) {
						subsArr.push(data.sub[k]);
					}
					setSubCatArray(subsArr);
				}
			} catch (error) {
				console.error('Failed to fetch c:', error);
			} finally {
				setIsLoading(false); // Stop loading
			}
		};
		
		if (id) {
			fetchData();
		} else {
			setCatName('');
			setOrder('');
			setSubCatArray([]);
		}
	}, [id, navigate, showAlert]);
	
	const handleSubcategoryChange = (index, value) => {
		// Update the sub-category at the specified index with the new value
		setSubCatArray(prevSubs => {
			// Create a new copy of the previous sub-categories array
			const updatedSubs = [...prevSubs];
			// Update the sub-category at the specified index
			updatedSubs[index] = value;
			// Return the updated sub-categories array
			return updatedSubs;
		});
	};
	
	const handleAddSubcategory = () => {
		setSubCatArray(prevSubs => {
			// Create a new array with all previous subcategories
			const updatedSubs = [...prevSubs];
			// Add a new subcategory (empty string or a default value)
			updatedSubs.push('');
			// Return the updated array with the new subcategory
			return updatedSubs;
		});
	};
	
	const deleteSub = (index) => {
		const isConfirmed = window.confirm("Are you sure you want to delete subcategory?");
		if (isConfirmed) {
			setSubCatArray(prevSubs => {
				// Filter out the subcategory at the given index
				const updatedSubs = prevSubs.filter((_, i) => i !== index);
				// Return the updated subcategories array
				return updatedSubs;
			});
		}
	};
	
	const handleSubmit = async (event) => {
		event.preventDefault();
		const apiEndpoint = id ? `hmpboapi/category/${id}` : 'hmpboapi/category';
		const method = id ? 'PATCH' : 'POST'; // Use PUT for update and POST for create
		
		let x = 0;
		let subs = {};
		subCatArray.forEach(function (one) {
			x++;
			subs[x.toString()] = one;
		});
		
		const options = {
			endpoint: apiEndpoint,
			method: method,
			data: {
				name: cName,
				order: order,
				sub: subs
			}
		};
		
		try {
			await utils.callApi(options, navigate, showAlert, setBackdropVisible);
			showAlert("Operation successful.", 'success');
			if (!id) {
				navigate('/category');
			}
			// Handle successful operation (redirect or show a success message)
		} catch (error) {
			console.error('Failed to update:', error);
			// Handle errors appropriately
		}
	};
	
	if (isLoading) {
		return <Spinner animation="border" role="status">
			<span className="sr-only"></span>
		</Spinner>// Display a spinner or loading message
	}
	
	return (
		<div>
			<h2>{id ? 'Edit' : 'Create'} Category</h2>
			<div className="d-flex justify-content-end">
				<Link to="/category" className="btn btn-secondary mb-3">
					Back to List
				</Link>
			</div>
			
			<Form onSubmit={handleSubmit} className="input-table">
				
				<Form.Group as={Row} controlId="formName" className="d-flex">
					<div className="col-sm-3">
						<Form.Label>Category Name</Form.Label>
					</div>
					<div className="col-sm-8">
						<Form.Control type="text"
						              value={cName}
						              onChange={(e) => setCatName(e.target.value)}
						              required
						/></div>
				
				</Form.Group>
				<Form.Group as={Row} controlId="formOrder" className="d-flex">
					<div className="col-sm-3">
						<Form.Label>Category Order</Form.Label>
					</div>
					<div className="col-sm-8">
						<Form.Control type="text"
						              value={order}
						              onChange={(e) => setOrder(e.target.value)}
						              required
						/></div>
				
				</Form.Group>
				
				{subCatArray.map((value, index) => (
					<Form.Group as={Row} key={index} controlId={`formSub${index}`}>
						<div className="col-sm-1"></div>
						<div className="col-sm-2">
							<Form.Label>Sub-Category {index + 1}</Form.Label>
						</div>
						
						<div className="col-sm-7">
							<Form.Control
								type="text"
								value={value}
								onChange={e => handleSubcategoryChange(index, e.target.value)}
							/>
						</div>
						<div className="col-sm-1">
							<OverlayTrigger
								overlay={<Tooltip>Delete</Tooltip>}>
								<Button
									variant="danger"
									onClick={() => deleteSub(index)}
									className="m-1 btn-sm btn-extra-sm">
									<i className="mdi mdi-delete"></i>
								</Button>
							</OverlayTrigger>
						
						</div>
					</Form.Group>
				))}
				
				<div className="d-flex">
					<div className="col-sm-9">
						<br/>
						<Button variant="primary" type="submit">
							{id ? 'Update' : 'Create'} Category
						</Button>
					</div>
					
					<div className="col-sm-3 justify-content-end">
						<Button variant="success" onClick={handleAddSubcategory} className="mb-3">
							Add New Sub-Category
						</Button>
					
					</div>
				</div>
			
			</Form>
		</div>
	);
}

export default CreateRecord;
