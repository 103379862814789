/*
 * Copyright (C) 2023 Corsair M360, Inc - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import React, {useState, useEffect, useContext} from 'react';
import {Form, Button, Spinner, Row, Col, Container, Tabs, Tab, Tooltip, OverlayTrigger, Table} from 'react-bootstrap';
import {useParams, useNavigate, Link} from 'react-router-dom';
import utils from '../../../utils/api';
import {AlertContext} from '../../../components/AlertContext';
import {useBackdrop} from '../../../components/BackdropContext';

function CreateHerbPage() {
	const [isLoading, setIsLoading] = useState(false);
	const [comHerbName, setComHerbName] = useState('');
	const [componentHerb, setComponentHerb] = useState('');
	const [selectedComponents, setSelectedComponents] = useState(new Set());
	
	const [recordTypeList, setRecordTypeList] = useState([]);
	const [selectedRecordType, setSelectedRecordType] = useState('');
	
	const [originalList, setOriginalList] = useState([]);
	const [herbsList, setHerbsList] = useState([]);
	
	const [genusList, setGenusList] = useState([]);
	const [selectedGenus, setSelectedGenus] = useState('');
	
	const [specie, setSpecie] = useState('');
	const [scientificName, setScientificName] = useState('');
	const [familyName, setFamilyName] = useState('');
	const [commonName, setCommonName] = useState('');
	const [directorsNote, setDirectorsNote] = useState('');
	const [originalResearchName, setOriginalResearchName] = useState('');
	const [originalResearchDate, setOriginalResearchDate] = useState('');
	
	const [latestUpdatesName, setLatestUpdatesName] = useState('');
	const [latestUpdatesDate, setLatestUpdatesDate] = useState('');
	
	const [latestUpdatesNamePending, setLatestUpdatesNamePending] = useState('');
	const [latestUpdatesDatePending, setLatestUpdatesDatePending] = useState('');
	
	const [majorRevisionName, setMajorRevisionName] = useState('');
	const [majorRevisionDate, setMajorRevisionDate] = useState('');
	
	const [herbActions, setHerbActions] = useState([]);
	const [selectedHerbActions, setSelectedHerbActions] = useState(new Set());
	
	const [healthIssues, setHealthIssues] = useState([]);
	const [selectedHealthIssues, setSelectedHealthIssues] = useState(new Set());
	
	const [permissions, setPermissions] = useState([]);
	const [selectedPermissions, setSelectedPermissions] = useState(new Set());
	
	const {id} = useParams();
	const navigate = useNavigate();
	const {showAlert} = useContext(AlertContext);
	const {setBackdropVisible} = useBackdrop();
	
	const access = {
		permissions: utils.checkAccess({method: 'PATCH', route: '/herb/:id/permissions'}),
		publish: utils.checkAccess({method: 'PATCH', route: '/herb/publish/:id'}),
		update: utils.checkAccess({method: 'PATCH', route: '/herb/:id'})
	};
	
	const handlePermissionsChange = (actionId) => {
		setSelectedPermissions(prevSelected => {
			const updatedSelected = new Set(prevSelected);
			if (updatedSelected.has(actionId)) {
				updatedSelected.delete(actionId);
			} else {
				updatedSelected.add(actionId);
			}
			return updatedSelected;
		});
	};
	
	const handleHerbActionChange = (actionId) => {
		setSelectedHerbActions(prevSelectedActions => {
			const updatedSelectedActions = new Set(prevSelectedActions);
			if (updatedSelectedActions.has(actionId)) {
				updatedSelectedActions.delete(actionId);
			} else {
				updatedSelectedActions.add(actionId);
			}
			return updatedSelectedActions;
		});
	};
	
	const handleHealthIssuesChange = (actionId) => {
		setSelectedHealthIssues(prevSelectedActions => {
			const updatedSelectedActions = new Set(prevSelectedActions);
			if (updatedSelectedActions.has(actionId)) {
				updatedSelectedActions.delete(actionId);
			} else {
				updatedSelectedActions.add(actionId);
			}
			return updatedSelectedActions;
		});
	};
	
	const addHerb = () => {
		if (componentHerb) {
			const herbToAdd = herbsList.find(herb => herb._id === componentHerb);
			
			// Create a new Set from the existing Set
			const updatedComponents = new Set(selectedComponents);
			
			if (herbToAdd) {
				// Add the new component directly to the Set
				updatedComponents.add({
					id: componentHerb,
					code: herbToAdd.code,
					commonName: herbToAdd.commonName,
					scientificName: herbToAdd.scientificName
				});
			}
			
			// Update the state
			setSelectedComponents(updatedComponents);
			setComponentHerb('');
		}
	};
	
	const addHerbName = () => {
		if (comHerbName) {
			// Create a new Set from the existing Set
			const updatedComponents = new Set(selectedComponents);
			
			// Add the new component directly to the Set
			updatedComponents.add({
				scientificName: comHerbName
			});
			// Update the state
			setSelectedComponents(updatedComponents);
			setComponentHerb('');
		}
	};
	
	const deleteHerb = (idToDelete) => {
		const isConfirmed = window.confirm("Are you sure you want to delete herb?");
		if (isConfirmed) {
			setSelectedComponents(prevComponents => {
				// Filter out the component with the given ID
				const updatedComponents = new Set([...prevComponents].filter(component => component.id !== idToDelete));
				// Return the updated components Set
				return updatedComponents;
			});
			
			// After deleting the herb, you might also need to update the herbsList
			// to make the deleted herb available for selection again
			// getFilteredHerbsList();
		}
	};
	
	const getFilteredHerbsList = (orgList) => {
		let herbList = originalList;
		if (orgList && orgList.length) {
			herbList = orgList;
		}
		// Extracting IDs from selectedComponents
		const selectedIds = new Set(Array.from(selectedComponents).map(component => component.id));
		
		if (herbList && herbList.length) {
			if (selectedIds && selectedIds.size) {
				// Filtering herbsList to exclude selected herbs
				const list = herbList.filter(herb => !selectedIds.has(herb._id));
				setHerbsList(list);
			} else {
				setHerbsList(herbList);
			}
		}
	};
	
	const allHerbsList = async () => {
		if (id) {
			try {
				const data = await utils.callApi({
					method: 'POST',
					endpoint: 'hmpboapi/search/herb',
					data: {
						type: 'herb'
					}
				});
				if (data) {
					setOriginalList(data);
					setHerbsList(data);
					setTimeout(function () {
						getFilteredHerbsList(data);
					}, 1500);
				}
			} catch (error) {
				console.error('Failed to fetch list:', error);
				// Handle errors appropriately
			}
		}
	};
	
	const fetchTypeList = async () => {
		try {
			const data = await utils.callApi({
				endpoint: 'hmpboapi/recordType'
			}, null, showAlert);
			setRecordTypeList(data);
		} catch (error) {
			console.error('Failed to fetch type list:', error);
		}
	};
	
	const fetchGenusList = async () => {
		try {
			const data = await utils.callApi({
				endpoint: 'hmpboapi/genus'
			}, null, showAlert);
			setGenusList(data);
		} catch (error) {
			console.error('Failed to fetch genus list:', error);
		}
	};
	
	const fetchHerbActionsList = async () => {
		try {
			const data = await utils.callApi({
				endpoint: 'hmpboapi/herbAction'
			}, null, showAlert);
			setHerbActions(data);
		} catch (error) {
			console.error('Failed to fetch herbAction list:', error);
		}
	};
	
	const fetchHealthList = async () => {
		try {
			const data = await utils.callApi({
				endpoint: 'hmpboapi/healthIssue'
			}, null, showAlert);
			setHealthIssues(data);
		} catch (error) {
			console.error('Failed to fetch healthIssue list:', error);
		}
	};
	
	const fetchGroupList = async () => {
		try {
			const data = [
				{
					name: "Individual",
					value: "Individual"
				},
				{
					name: "Professional",
					value: "Professional"
				},
				{
					name: "Academic",
					value: "Academic"
				},
				{
					name: "Registered User",
					value: "Registered User"
				},
				// {
				// 	name: "MemberRole",
				// 	value: "MemberRole"
				// }
			];
			setPermissions(data);
		} catch (error) {
			console.error(error);
		}
	};
	
	const fetchHerb = async () => {
		if (id) {
			try {
				const data = await utils.callApi({
					endpoint: `hmpboapi/herb/${id}`
				}, navigate, showAlert, setBackdropVisible);
				
				if (data) {
					setSelectedGenus(data.genus);
					setSelectedRecordType(data.recordType);
					setSpecie(data.specie);
					setCommonName(data.commonName);
					setScientificName(data.scientificName);
					setFamilyName(data.familyName);
					setDirectorsNote(data.directorsNote);
					
					const selectedActions = new Set(data.herbAction);
					setSelectedHerbActions(selectedActions);
					
					const selectedIssues = new Set(data.healthIndication);
					setSelectedHealthIssues(selectedIssues);
					
					const pmsions = new Set(data.permissions);
					setSelectedPermissions(pmsions);
					
					if (data.components) {
						setSelectedComponents(new Set(data.components));
					} else {
						setSelectedComponents(new Set());
					}
					if (data.history) {
						if (data.history.originalResearch) {
							setOriginalResearchName(data.history.originalResearch.name);
							setOriginalResearchDate(data.history.originalResearch.date);
						}
						if (data.history.latestUpdates) {
							setLatestUpdatesName(data.history.latestUpdates.name);
							setLatestUpdatesDate(data.history.latestUpdates.date);
						}
						if (data.history.latestUpdatesPending) {
							setLatestUpdatesNamePending(data.history.latestUpdatesPending.name);
							setLatestUpdatesDatePending(data.history.latestUpdatesPending.date);
						}
						if (data.history.majorRevision) {
							setMajorRevisionName(data.history.majorRevision.name);
							setMajorRevisionDate(data.history.majorRevision.date);
						}
					}
				}
				
			} catch (error) {
				console.error('Failed to get herb:', error);
			}
		} else {
			setCommonName('');
			setScientificName('');
			setFamilyName('');
			setSelectedGenus('');
			setSelectedRecordType('herb');
			setSpecie('');
			setDirectorsNote('');
			
			setOriginalResearchName('');
			setOriginalResearchDate('');
			setLatestUpdatesName('');
			setLatestUpdatesDate('');
			setLatestUpdatesNamePending('');
			setLatestUpdatesDatePending('');
			
			setMajorRevisionName('');
			setMajorRevisionDate('');
			
			setSelectedHerbActions(new Set());
			setSelectedHealthIssues(new Set());
			setSelectedPermissions(new Set());
			setSelectedComponents(new Set());
		}
	};
	
	const fetchData = async () => {
		setIsLoading(true);
		setComponentHerb('');
		await fetchHerb();
		await Promise.all([
			fetchTypeList(),
			fetchGenusList(),
			fetchHerbActionsList(),
			fetchHealthList(),
			fetchGroupList()
		]);
		await allHerbsList();
		setIsLoading(false);
	};
	
	const clearPermissions = async (event) => {
		event.preventDefault();
		const isConfirmed = window.confirm("Are you sure you want to clear all permissions?");
		
		setSelectedPermissions(new Set());
		if (isConfirmed) {
			const options = {
				endpoint: `hmpboapi/herb/${id}/permissions`,
				method: 'PATCH',
				data: {
					permissions: []
				}
			};
			try {
				await utils.callApi(options, navigate, showAlert, setBackdropVisible);
				showAlert("Operation successful.", 'success');
			} catch (error) {
				console.error('Failed to update:', error);
				// Handle errors appropriately
			}
		}
		
	};
	
	const handlePermissions = async (event) => {
		event.preventDefault();
		// Convert Sets to Arrays
		const permissionsArray = Array.from(selectedPermissions);
		
		const options = {
			endpoint: `hmpboapi/herb/${id}/permissions`,
			method: 'PATCH',
			data: {
				permissions: permissionsArray
			}
		};
		
		try {
			await utils.callApi(options, navigate, showAlert, setBackdropVisible);
			showAlert("Operation successful.", 'success');
			if (!id) {
				navigate("/herbs");
			}
			// Handle successful operation (redirect or show a success message)
		} catch (error) {
			console.error('Failed to update:', error);
			// Handle errors appropriately
		}
	};
	
	const applyUpdates = async (event) => {
		event.preventDefault();
		const isConfirmed = window.confirm("Are you sure you want to apply new date?");
		if (isConfirmed) {
			if (latestUpdatesNamePending || latestUpdatesDatePending) {
				setLatestUpdatesName(latestUpdatesNamePending);
				setLatestUpdatesDate(latestUpdatesDatePending);
			}
			setLatestUpdatesNamePending("");
			setLatestUpdatesDatePending("");
		}
		
	};
	
	const saveComponents = async (event) => {
		event.preventDefault();
		// Convert Sets to Arrays
		const selectedComponentsArray = Array.from(selectedComponents);
		const herbData = {};
		
		if (selectedRecordType !== 'herb') {
			herbData.components = selectedComponentsArray;
		}
		const options = {
			endpoint: `hmpboapi/herb/${id}`,
			method: 'PATCH',
			data: herbData
		};
		
		try {
			await utils.callApi(options, navigate, showAlert, setBackdropVisible);
			showAlert("Operation successful.", 'success');
			// Handle successful operation (redirect or show a success message)
		} catch (error) {
			console.error('Failed to update:', error);
			// Handle errors appropriately
		}
	};
	
	const handleSubmit = async (event) => {
		event.preventDefault();
		// Convert Sets to Arrays
		const herbActionsArray = Array.from(selectedHerbActions);
		const healthIssuesArray = Array.from(selectedHealthIssues);
		
		const apiEndpoint = id ? `hmpboapi/herb/${id}` : 'hmpboapi/herb';
		const method = id ? 'PATCH' : 'POST'; // Use PUT for update and POST for create
		
		const herbData = {
			"recordType": selectedRecordType,
			"genus": selectedGenus,
			"specie": specie,
			"scientificName": scientificName.trim(),
			"familyName": familyName,
			"commonName": commonName || "",
			"directorsNote": directorsNote || "",
			"herbAction": herbActionsArray,
			"healthIndication": healthIssuesArray,
			"history": {}
		};
		
		if (originalResearchName) {
			herbData.history.originalResearch = {
				"name": originalResearchName || "",
				"date": originalResearchDate || ""
			}
		}
		if (latestUpdatesName) {
			herbData.history.latestUpdates = {
				"name": latestUpdatesName || "",
				"date": latestUpdatesDate || ""
			};
		}
		if (latestUpdatesNamePending || latestUpdatesDatePending) {
			herbData.history.latestUpdatesPending = {
				"name": latestUpdatesNamePending || "",
				"date": latestUpdatesDatePending || ""
			};
		}
		if (majorRevisionName) {
			herbData.history.majorRevision = {
				"name": majorRevisionName,
				"date": majorRevisionDate
			};
		}
		const options = {
			endpoint: apiEndpoint,
			method: method,
			data: herbData
		};
		
		try {
			await utils.callApi(options, navigate, showAlert, setBackdropVisible);
			showAlert("Operation successful.", 'success');
			if (!id) {
				navigate("/herbs");
			}
			// Handle successful operation (redirect or show a success message)
		} catch (error) {
			console.error('Failed to update:', error);
			// Handle errors appropriately
		}
	};
	
	const handleSelectedGenusChange = (name) => {
		setSelectedGenus(name);
		setScientificName(`${name} ${specie}`);
	};
	
	const handleSelectedSpeciesChange = (name) => {
		setSpecie(name);
		setScientificName(`${selectedGenus} ${name}`);
	};
	
	useEffect(() => {
		
		fetchData();
		
	}, [id]);
	
	useEffect(() => {
		if (herbsList && herbsList.length) {
			getFilteredHerbsList();
		}
		setComponentHerb('');
	}, [selectedComponents]);
	
	
	if (isLoading) {
		return (
			<Spinner animation="border" role="status">
				<span className="sr-only"> </span>
			</Spinner>
		);
	}
	
	return (
		<Container>
			<h2>{id ? 'Edit' : 'Create'} Herb {scientificName}</h2>
			<div className="d-flex justify-content-end">
				{id &&
					<Link to={`/list-entry/${id}`} className="btn btn-info mb-3">List Entries</Link>
				}
				&nbsp;
				<Link to="/herbs" className="btn btn-secondary mb-3">Back to Herbs List</Link>
			</div>
			
			<Tabs defaultActiveKey="mainInfo" id="herb-tabs" className="mb-3">
				<Tab eventKey="mainInfo" title="Herb">
					<Form onSubmit={handleSubmit} className="input-table">
						
						<Form.Group as={Row} className="odd-row">
							<Form.Label column sm={3}>Record Type</Form.Label>
							<Col sm={9}>
								<Form.Control as="select" value={selectedRecordType} required
								              onChange={(e) => setSelectedRecordType(e.target.value)}>
									<option value="">Select Type</option>
									{recordTypeList.map((type, index) => (
										<option key={index} value={type.name}>
											{type.title}</option>
									))}
								</Form.Control>
							</Col>
						</Form.Group>
						
						<Form.Group as={Row} className="even-row">
							<Form.Label column sm={3}>Genus</Form.Label>
							<Col sm={9}>
								<Form.Control as="select" value={selectedGenus} required
								              onChange={(e) => handleSelectedGenusChange(e.target.value)}>
									<option value="">Select Genus</option>
									{genusList.map((genus, index) => (
										<option key={index} value={genus.name}>{genus.name}</option>
									))}
								</Form.Control>
							</Col>
						</Form.Group>
						
						{/* Repeat this structure for other fields */}
						<Form.Group as={Row} className="">
							<Form.Label column sm={3}>Specie</Form.Label>
							<Col sm={9}>
								<Form.Control type="text"
								              value={specie}
								              onChange={(e) => handleSelectedSpeciesChange(e.target.value)}/>
							</Col>
						</Form.Group>
						
						<Form.Group as={Row} className="even-row">
							<Form.Label column sm={3}>Scientific Name</Form.Label>
							<Col sm={9}>
								{/*<Form.Control type="text" value={scientificName} required disabled*/}
								{/*              onChange={(e) => setScientificName(e.target.value)}/>*/}
								
								<Form.Control type="text" value={scientificName} required disabled/>
							</Col>
						</Form.Group>
						
						<Form.Group as={Row} className="">
							<Form.Label column sm={3}>Family Name</Form.Label>
							<Col sm={9}>
								<Form.Control type="text" value={familyName}
								              onChange={(e) => setFamilyName(e.target.value)}/>
							</Col>
						</Form.Group>
						
						<Form.Group as={Row} className="even-row">
							<Form.Label column sm={3}>Common Name</Form.Label>
							<Col sm={9}>
								<Form.Control type="text" value={commonName}
								              onChange={(e) => setCommonName(e.target.value)}
								              required/>
							</Col>
						</Form.Group>
						
						<Form.Group as={Row} className="">
							<Form.Label column sm={3}>Director's Note</Form.Label>
							<Col sm={9}>
								<Form.Control
									as="textarea"
									rows={3} // Sets the height to accommodate 3 lines
									type="text" value={directorsNote}
									onChange={(e) => setDirectorsNote(e.target.value)}/>
							</Col>
						</Form.Group>
						
						<Form.Group as={Row} className="even-row">
							<Form.Label column sm={3}>
								Health Indications
							</Form.Label>
							
							<Col sm={9} className="scrollable-wrapper">
								<Row className="scrollable-container">
									{healthIssues.map((action, index) => (
										<Col sm={4} key={action.name}>
											<Form.Check>
												<Form.Check.Input
													type="checkbox"
													id={`health-issue-${action._id}`}
													checked={selectedHealthIssues.has(action.name)}
													onChange={() => handleHealthIssuesChange(action.name)}
												/>
												<Form.Check.Label htmlFor={`health-issue-${action._id}`}>
													{action.name}
												</Form.Check.Label>
											</Form.Check>
										</Col>
									))}
								</Row>
							</Col>
						</Form.Group>
						
						<Form.Group as={Row}>
							<Form.Label column sm={3}>
								Herb Actions
							</Form.Label>
							<Col sm={9} className="scrollable-wrapper">
								<Row className="scrollable-container">
									{herbActions.map((action, index) => (
										<Col sm={4} key={action._id}>
											<Form.Check>
												<Form.Check.Input
													type="checkbox"
													id={`action-${action._id}`}
													checked={selectedHerbActions.has(action.name)}
													onChange={() => handleHerbActionChange(action.name)}
												/>
												<Form.Check.Label htmlFor={`action-${action._id}`}>
													{action.name}
												</Form.Check.Label>
											</Form.Check>
										</Col>
									))}
								</Row>
							</Col>
						</Form.Group>
						
						{/* Additional fields for history and herb actions can be added similarly */}
						<Form.Group as={Row} className="even-row">
							<Form.Label column sm={3}>
								Original Research By
							</Form.Label>
							<Col sm={9}>
								<Form.Control type="text" value={originalResearchName}
								              onChange={(e) => setOriginalResearchName(e.target.value)}/>
							</Col>
						</Form.Group>
						
						<Form.Group as={Row}>
							<Form.Label column sm={3}>
								Date Of Original Research
							</Form.Label>
							<Col sm={9}>
								<Form.Control type="text" value={originalResearchDate}
								              onChange={(e) => setOriginalResearchDate(e.target.value)}/>
							</Col>
						</Form.Group>
						
						<Form.Group as={Row} className="even-row">
							<Form.Label column sm={3}>
								Major Revision By
							</Form.Label>
							<Col sm={9}>
								<Form.Control type="text" value={majorRevisionName}
								              onChange={(e) => setMajorRevisionName(e.target.value)}/>
							</Col>
						</Form.Group>
						
						<Form.Group as={Row}>
							<Form.Label column sm={3}>
								Date of Major Revision
							</Form.Label>
							<Col sm={9}>
								<Form.Control type="text" value={majorRevisionDate}
								              onChange={(e) => setMajorRevisionDate(e.target.value)}/>
							</Col>
						</Form.Group>
						
						<Form.Group as={Row} className="even-row">
							<Form.Label column sm={3}>
								Latest Updates By
							</Form.Label>
							<Col sm={3}>
								<Form.Control type="text" value={latestUpdatesName}
								              onChange={(e) => setLatestUpdatesName(e.target.value)}/>
							</Col>
							
							<Form.Label column sm={3}>
								Pending Latest Updates By
							</Form.Label>
							<Col sm={3}>
								<Form.Control type="text" value={latestUpdatesNamePending}
								              onChange={(e) => setLatestUpdatesNamePending(e.target.value)}/>
							</Col>
						</Form.Group>
						
						<Form.Group as={Row} className="">
							<Form.Label column sm={3}>
								Date Latest Updates
							</Form.Label>
							<Col sm={3}>
								<Form.Control type="text" value={latestUpdatesDate}
								              onChange={(e) => setLatestUpdatesDate(e.target.value)}/>
							</Col>
							
							<Form.Label column sm={3}>
								Pending Date Latest Updates
							</Form.Label>
							<Col sm={3}>
								<Form.Control type="text" value={latestUpdatesDatePending}
								              onChange={(e) => setLatestUpdatesDatePending(e.target.value)}/>
								
								{latestUpdatesDatePending && access.publish &&
									<div className="d-flex justify-content-end" style={{marginTop: '5px'}}>
										<Button className="m-1 btn-sm btn-extra-sm" variant="info"
										        onClick={applyUpdates}>
											Apply Latest Updates
										</Button>
									</div>
								}
							
							</Col>
						</Form.Group>
						
						<br/>
						<Button variant="primary" type="submit">{id ? 'Update' : 'Create'} Herb</Button>
					
					</Form>
				</Tab>
				
				{id && access.permissions &&
					<Tab eventKey="permissions" title="Permissions">
						<Form onSubmit={handlePermissions} className="input-table">
							<Form.Group as={Row}>
								<Form.Label column sm={3}>
									Permissions
								</Form.Label>
								<Col sm={9} className="scrollable-wrapper">
									<Row className="scrollable-container">
										{permissions.map((action, index) => (
											<Col sm={4} key={action.name}>
												<Form.Check>
													<Form.Check.Input
														type="checkbox"
														id={`action-${action.name}`}
														checked={selectedPermissions.has(action.name)}
														onChange={() => handlePermissionsChange(action.name)}
													/>
													<Form.Check.Label htmlFor={`action-${action.name}`}>
														{action.name}
													</Form.Check.Label>
												</Form.Check>
											</Col>
										))}
									</Row>
								</Col>
							</Form.Group>
							
							<br/>
							
							<div className="d-flex">
								<div className="col-sm-10">
									<Button variant="primary" type="submit">
										Update Permissions
									</Button>
								</div>
								
								<div className="col-sm-2 justify-content-end">
									<Button variant="danger" onClick={clearPermissions}>
										Clear Permissions
									</Button>
								</div>
							</div>
						</Form>
					</Tab>
				}
				
				{id && (selectedRecordType !== 'herb') &&
					<Tab eventKey="components" title="Components">
						
						<div>
							Components List:
							<Table striped bordered hover className="table-smaller-rows">
								
								{Array.from(selectedComponents).map((component, index) => (
									<tr key={index}>
										<td>
											<OverlayTrigger
												overlay={<Tooltip>Delete</Tooltip>}>
												<Button
													variant="danger"
													onClick={() => deleteHerb(component.id)}
													className="m-1 btn-sm btn-extra-sm">
													<i className="mdi mdi-delete"></i>
												</Button>
											</OverlayTrigger>
										</td>
										
										<td>
											{component.scientificName}
											
											{component.commonName &&
												<span>({component.commonName})</span>}
										</td>
									</tr>
								))}
							</Table>
						
						</div>
						
						<Form className="input-table">
							
							<div>
								Enter a component name for components that don't exist in the HerbMed database
								or select one by viewing the HerbList
							</div>
							
							<br/>
							
							<Form.Group as={Row}>
								<Form.Label column sm={1}>
								
								</Form.Label>
								<Col sm={9}>
									<Form.Control type="text" value={comHerbName}
									              onChange={(e) => setComHerbName(e.target.value)}/>
								</Col>
								<Col sm={1}>
									<Button variant="info" onClick={addHerbName}>
										Add
									</Button>
								</Col>
							</Form.Group>
							
							<Form.Group as={Row}>
								<Form.Label column sm={1}>
									Herb
								</Form.Label>
								<Col sm={9}>
									<Form.Control as="select"
									              onChange={(e) => setComponentHerb(e.target.value)}>
										<option value="">Select Herb</option>
										{herbsList.map((herb, index) => (
											<option key={index} value={herb._id}>
												{herb.scientificName}
											</option>
										))}
									</Form.Control>
								</Col>
								<Col sm={1}>
									<Button variant="info" onClick={addHerb}>
										Add
									</Button>
								</Col>
							</Form.Group>
							
							<br/>
							
							<div className="d-flex">
								<div className="col-sm-10">
								
								</div>
								
								<div className="col-sm-2 justify-content-end">
									<Button variant="primary" onClick={saveComponents}>
										Save Components
									</Button>
								</div>
							</div>
						</Form>
					</Tab>
				}
			</Tabs>
		
		</Container>
	);
}

export default CreateHerbPage;
