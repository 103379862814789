/*
 * Copyright (C) 2023 Corsair M360, Inc - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import React, {useState, useEffect, useContext} from 'react';
import {Form, Button, Spinner, Row} from 'react-bootstrap';
import {useParams} from 'react-router-dom';
import {Link} from 'react-router-dom';
import utils from "../../../utils/api";
import {useNavigate} from 'react-router-dom';
import {AlertContext} from '../../../components/AlertContext';
import {useBackdrop} from '../../../components/BackdropContext';

function CreateAction() {
	const [isLoading, setIsLoading] = useState(false); // State for loading
	const [description, setDescription] = useState('');
	const [name, setName] = useState('');
	const [title, setTitle] = useState('');
	const [imagePath, setImagePath] = useState('');
	const {id} = useParams(); // Get the id param from the route
	const navigate = useNavigate();
	const {showAlert} = useContext(AlertContext);
	const {setBackdropVisible} = useBackdrop();
	
	useEffect(() => {
		const fetchName = async () => {
			setIsLoading(true); // Start loading
			try {
				const data = await utils.callApi({
					endpoint: `hmpboapi/recordType/${id}`
				}, navigate, showAlert, setBackdropVisible);
				if (data) {
					setName(data.name);
					setTitle(data.title);
					setImagePath(data.imagePath);
					setDescription(data.description);
				}
			} catch (error) {
				console.error('Failed to fetch type:', error);
			} finally {
				setIsLoading(false); // Stop loading
			}
		};
		if (id) {
			fetchName();
		} else {
			setName('');
			setTitle('');
			setImagePath('');
			setDescription('');
		}
	}, [id, navigate, showAlert]);
	
	const handleSubmit = async (event) => {
		event.preventDefault();
		const method = (id) ? 'PATCH' : 'POST'; // Use PUT for update and POST for create
		const options = {
			'endpoint': (id) ? `hmpboapi/recordType/${id}` : 'hmpboapi/recordType',
			'method': method,
			'data': {
				'name': name,
				'title': title,
				'description': description,
				'imagePath': imagePath
			}
		};
		try {
			await utils.callApi(options, navigate, showAlert, setBackdropVisible);
			showAlert("Operation successful.", 'success');
			
			if (!id) {
				navigate('/record-type');
			}
			// Handle successful operation (redirect or show a success message)
		} catch (error) {
			console.error('Failed to update:', error);
			// Handle errors appropriately
		}
	};
	
	if (isLoading) {
		return <Spinner animation="border" role="status">
			<span className="sr-only"></span>
		</Spinner>// Display a spinner or loading message
	}
	
	return (
		<div>
			<h2>{id ? 'Edit' : 'Create'} Record Type</h2>
			<div className="d-flex justify-content-end">
				<Link to="/record-type" className="btn btn-secondary mb-3">
					Back to List
				</Link>
			</div>
			
			<Form onSubmit={handleSubmit} className="input-table">
				
				<Form.Group as={Row} controlId="formTitle" className="d-flex">
					<div className="col-sm-3">
						<Form.Label>Title</Form.Label>
					</div>
					<div className="col-sm-8">
						<Form.Control type="text"
						              value={title}
						              onChange={(e) => setTitle(e.target.value)}
						              required
						/></div>
				
				</Form.Group>
				
				<Form.Group as={Row} controlId="formName" className="d-flex">
					<div className="col-sm-3">
						<Form.Label>Name</Form.Label>
					</div>
					<div className="col-sm-8">
						<Form.Control type="text"
						              value={name}
						              onChange={(e) => setName(e.target.value)}
						              required
						/></div>
				
				</Form.Group>
				
				<Form.Group as={Row} controlId="formDescription" className="d-flex">
					<div className="col-sm-3">
						<Form.Label>Description</Form.Label>
					</div>
					<div className="col-sm-8">
						<Form.Control type="text"
						              value={description}
						              onChange={(e) => setDescription(e.target.value)}
						/></div>
				
				</Form.Group>
				
				<Form.Group as={Row} controlId="formImage" className="d-flex">
					<div className="col-sm-3">
						<Form.Label>Image</Form.Label>
					</div>
					<div className="col-sm-8">
						<Form.Control type="text"
						              value={imagePath}
						              onChange={(e) => setImagePath(e.target.value)}
						/>
						
						{imagePath &&
							<img src={imagePath} alt="Preview"
							     style={{maxWidth: '50%', maxHeight: '70px', marginTop: '10px'}}/>}
					
					</div>
				
				</Form.Group>
				
				<br/>
				<Button variant="primary" type="submit">
					{id ? 'Update' : 'Create'} Record Type
				</Button>
			</Form>
		</div>
	);
}

export default CreateAction;
