/*
 * Copyright (C) 2023 Corsair M360, Inc - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import React, {useState, useEffect, useContext} from 'react';
import utils from '../../../utils/api';
import {Container, Button} from 'react-bootstrap';
import {Link, useNavigate} from 'react-router-dom';
import DynamicGrid from '../../../components/DynamicGrid';
import {AlertContext} from '../../../components/AlertContext';
import {useBackdrop} from '../../../components/BackdropContext';

function ListHerbPage() {
	const [herbList, setList] = useState([]);
	const [isLoading, setIsLoading] = useState(false); // State for loading
	const {setBackdropVisible} = useBackdrop();
	const navigate = useNavigate();
	const {showAlert} = useContext(AlertContext);
	
	const access = {
		delete: utils.checkAccess({method: 'DELETE', route: '/herb/:id'}),
		publish: utils.checkAccess({method: 'PATCH', route: '/herb/publish/:id'}),
		update: utils.checkAccess({method: 'PATCH', route: '/herb/:id'})
	};
	
	const handleRefresh = () => {
		fetchDataList();
	};
	
	const fetchDataList = async () => {
		setIsLoading(true); // Start loading
		try {
			const data = await utils.callApi({
				method: 'GET',
				endpoint: 'hmpboapi/herb'
			}, navigate, showAlert, setBackdropVisible);
			if (data) {
				data.forEach(function (one) {
					if (one.published) {
						one.published = 'Yes';
						if (!one.permissions || one.permissions.length === 0) {
							one.published = 'Yes *';
						}
					} else {
						one.published = 'No';
					}
				});
				setList(data);
			}
		} catch (error) {
			console.error('Failed to fetch herb list:', error);
		} finally {
			setIsLoading(false); // Stop loading
		}
	};
	
	useEffect(() => {
		fetchDataList();
	}, []);
	
	const handleEdit = (Id) => {
		navigate(`/edit-herb/${Id}`);
	};
	
	const handleDelete = async (Id) => {
		// Show confirm dialog
		const isConfirmed = window.confirm("Are you sure you want to delete this record?");
		
		if (isConfirmed) {
			let options = {
				endpoint: `hmpboapi/herb/${Id}`,
				method: 'DELETE'
			};
			try {
				await utils.callApi(options, null, showAlert, setBackdropVisible);
				fetchDataList();
			} catch (error) {
				console.error('Failed to delete:', error);
			}
		}
		
	};
	
	const handleUnPublish = async (Id) => {
		const isConfirmed = window.confirm("Are you sure you want to unPublish this record?");
		let options = {
			endpoint: `hmpboapi/herb/publish/${Id}`,
			method: 'PATCH',
			data: {
				published: false
			}
		};
		if (isConfirmed) {
			try {
				await utils.callApi(options, null, showAlert, setBackdropVisible);
				fetchDataList();
			} catch (error) {
				console.error('Failed to edit:', error);
			}
		}
		
	};
	
	const handlePublish = async (Id) => {
		const isConfirmed = window.confirm("Are you sure you want to publish this record?");
		let options = {
			endpoint: `hmpboapi/herb/publish/${Id}`,
			method: 'PATCH',
			data: {
				published: true
			}
		};
		if (isConfirmed) {
			try {
				await utils.callApi(options, null, showAlert, setBackdropVisible);
				fetchDataList();
			} catch (error) {
				console.error('Failed to edit:', error);
			}
		}
		
	};
	
	const viewEntry = (Id) => {
		navigate(`/list-entry/${Id}`);
	};
	
	const fields = [
		{headerName: 'Live', width: '5%', fieldName: 'published', headerTitle: 'Published'},
		{headerName: 'Type', width: '5%', fieldName: 'recordType'},
		// {headerName: 'Genus', fieldName: 'genus'},
		{headerName: 'Scientific Name', fieldName: 'scientificName'},
		{headerName: 'Common Name', fieldName: 'commonName'},
		{
			headerName: 'Date Created',
			fieldLength: 18,
			fieldName: 'created', className: "wordBreak"
		},
		{
			headerName: 'Last Edited',
			fieldLength: 18,
			fieldName: 'modified', className: "wordBreak"
		},
		{headerName: 'Unapproved Entries', width: '6%', fieldName: 'notApprovedEntryCount'},
		{headerName: 'Entries', width: '6%', fieldName: 'approvedEntryCount'},
		// Add other fields as necessary
	];
	
	// Actions definition with conditional logic
	const actions = (item) => [
		(access.update) && {
			label: 'Edit',
			icon: 'mdi mdi-pencil',
			variant: 'secondary',
			action: () => handleEdit(item._id)
		},
		(access.publish && item.published === 'No') && {
			label: 'Publish',
			icon: 'mdi mdi-cloud-upload',
			variant: 'primary',
			action: () => handlePublish(item._id),
		},
		(access.publish && item.published !== 'No') && {
			label: 'Un-Publish',
			icon: 'mdi mdi-cloud-off-outline',
			variant: 'danger',
			action: () => handleUnPublish(item._id),
		},
		{
			label: 'List Entries',
			icon: 'mdi mdi-format-list-bulleted',
			variant: 'info',
			action: () => viewEntry(item._id),
		},
		(access.delete) && {
			label: 'Delete',
			icon: 'mdi mdi-delete',
			variant: 'danger',
			action: () => handleDelete(item._id)
		}
	].filter(Boolean); // Remove false entries
	
	return (
		<Container><h2>Herbs</h2>
			
			<div className="d-flex">
				<div className="col-sm-6">
					<Button onClick={handleRefresh} className="mb-3" title="Refresh List">
						<i className="mdi mdi-refresh"></i>
					</Button>
				</div>
				<div className="col-sm-6 d-flex align-items-center justify-content-end">
					<div className="d-flex justify-content-end">
						<Link to="/create-herb" className="btn btn-secondary mb-3">
							Add New</Link>
					</div>
				</div>
			</div>
			
			{isLoading ? (
				<p>Loading...</p> // Display loading message
			) : (
				<DynamicGrid data={herbList} fields={fields} actions={actions}/>
			)}
			
			<br/>
			<div> An * for free herb</div>
		</Container>
	);
	
}

export default ListHerbPage;