/*
 * Copyright (C) 2023 Corsair M360, Inc - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

// AlertContext.js
import React, {createContext, useState, useCallback} from 'react';

export const AlertContext = createContext();

export const AlertProvider = ({children}) => {
	const [alert, setAlert] = useState({show: false, messages: [], variant: 'success'});
	
	const showAlert = useCallback((messages, variant = 'success', timeout) => {
		setAlert({
			show: true,
			messages: Array.isArray(messages) ? messages : [messages], variant
		});
		window.scrollTo(0, 0);
		if (timeout) {
			setTimeout(function () {
				setAlert({
					show: false,
				});
			}, timeout);
		}
	}, [setAlert]);
	
	const hideAlert = useCallback(() => {
		setAlert({...alert, show: false});
	}, [alert]);
	
	return (
		<AlertContext.Provider value={{alert, showAlert, hideAlert}}>
			{children}
		</AlertContext.Provider>
	);
};
