/*
 * Copyright (C) 2023 Corsair M360, Inc - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import React from 'react';
import utils from "../../utils/api";
import {AlertContext} from "../../components/AlertContext";

function HomePage() {
	
	return (
		<div>
			Welcome to HerbMedPro BackOffice
		</div>
	);
}

export default HomePage;
