/*
 * Copyright (C) 2023 Corsair M360, Inc - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import axios from 'axios';

const BASE_URL = process.env.REACT_APP_API_URL;
const APP_KEY = process.env.REACT_APP_M360_KEY;
const APP_ENV = process.env.REACT_APP_ENV;

async function callApi(options = {}, navigate, showAlert, setBackdropVisible) {
	function printErrors(errors) {
		for (let code in errors) {
			showAlert(errors[code].message, 'danger');
		}
	}
	
	if (setBackdropVisible) {
		setBackdropVisible(true);
	}
	
	try {
		const response = await executeApi(options, navigate);
		if (setBackdropVisible) {
			setBackdropVisible(false);
		}
		return response;
	} catch (error) {
		if (setBackdropVisible) {
			setBackdropVisible(false);
		}
		
		if (error && error.data && error.data.errors) {
			if (options.endpoint === 'logout') {
				forceLogout(navigate);
				return;
			}
			
			if (error.data.errors['142']) {
				try {
					const token = JSON.parse(localStorage.getItem('token'));
					let opt = {
						endpoint: 'token',
						method: 'PUT',
						data: {
							'refresh': token.refresh_token
						}
					};
					
					const tryLogin = await executeApi(opt);
					if (tryLogin) {
						localStorage.setItem('token', JSON.stringify(tryLogin));
						// retry call
						return await utils.callApi(options, navigate, showAlert, setBackdropVisible);
					} else {
					
					}
				} catch (e) {
					console.error(e);
					if (showAlert) {
						// 142
						showAlert("Session expired", 'info');
					}
					forceLogout(navigate);
				}
			} else {
				if (error.data.errors['143'] || error.data.errors['160']) {
					if (showAlert) {
						printErrors(error.data.errors);
					}
					setTimeout(function () {
						forceLogout(navigate);
					}, 1500);
				} else if (error.data.errors['147']) {
					// code: 147, message: "Invalid or No Access Token was found!"
					if (showAlert) {
						showAlert("Session expired", 'info', 2000);
					}
				} else if (error.data.errors['171']) {
					console.error(options.method, " ", options.endpoint, " - ", error.data.errors);
				} else {
					if (showAlert) {
						printErrors(error.data.errors);
					}
					throw error.data.errors;
				}
			}
			
		} else {
			if (error) {
				console.error("error data:");console.error(error.data);
				if (showAlert && error.data && error.data.message) {
					showAlert(error.data.message, 'danger');
				}
				throw error;
			}
			
		}
		
	}
}

async function executeApi(options = {}, navigate, showAlert, setBackdropVisible) {
	const url = `${BASE_URL}/${options.endpoint}`;
	
	const APIHeaders = options.headers || {};
	APIHeaders.env = APP_ENV || "dev";
	APIHeaders['x-key'] = APP_KEY;
	APIHeaders['Content-Type'] = 'application/json';
	
	try {
		const storedToken = JSON.parse(localStorage.getItem('token'));
		if (storedToken && storedToken.access_token) {
			APIHeaders['Authorization'] = storedToken.access_token;
		}
		
	} catch (e) {
		console.error(e);
	}
	
	options.headers = APIHeaders;
	
	try {
		const response = await axios({
			url: url,
			...options,
		});
		
		if (response.status === 200) {
			// console.warn("response.data.data:");
			// console.warn(response.data);
			if (response.data.data) {
				return response.data.data;
			} else {
				return true;
			}
		}
		return response.data;
	} catch (error) {
		if (error.response) {
			//console.error(error.response);
			throw error.response;
		} else {
			console.error(error);
		}
		
	}
}

function forceLogout(navigate) {
	localStorage.removeItem('token');
	localStorage.removeItem('user');
	localStorage.removeItem('acl');
	if (navigate) {
		navigate('/login');
	}
}

function getApiRoute(schema, context, module = 'hmpboapi', section = 'apis', version = 'v1') {
	let routeMethod = context.method.toLowerCase();
	let routeUrl = context.route;
	if (context.module) module = context.module;
	// if (context.section) section = context.section;
	//
	let apiRoute = null;
	if (schema[module][version][section]) {
		if (schema[module][version][section][routeMethod]
			&& schema[module][version][section][routeMethod][routeUrl]) {
			apiRoute = schema[module][version][section][routeMethod][routeUrl];
		}
	}
	return apiRoute;
}

function hasComponentAccess(schema, context, module = 'hmpboapi', section = 'apis', version = 'v1') {
	let apiRoute = getApiRoute(schema, context, module, section, version);
	let authenticated = false;
	
	if (apiRoute && apiRoute.access) {
		authenticated = true;
	}
	return authenticated;
}

function checkAccess(context) {
	if (localStorage.getItem('acl')) {
		try {
			const acl = JSON.parse(localStorage.getItem('acl'));
			return hasComponentAccess(acl.schema, context);
		} catch (e) {
			console.error(e);
		}
	} else {
		return null;
	}
	
}

const utils = {
	callApi,
	checkAccess,
	forceLogout
};

export default utils;
