/*
 * Copyright (C) 2023 Corsair M360, Inc - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import React, {useState, useEffect, useContext} from 'react';
import {Form, Button, Spinner} from 'react-bootstrap';
import {useParams} from 'react-router-dom';
import {Link} from 'react-router-dom';
import utils from "../../../utils/api";
import {useNavigate} from 'react-router-dom';
import {AlertContext} from '../../../components/AlertContext';
import {useBackdrop} from '../../../components/BackdropContext';

function CreateGenus() {
	const [isLoading, setIsLoading] = useState(false); // State for loading
	const [genusName, setGenusName] = useState('');
	const {id} = useParams(); // Get the id param from the route
	const navigate = useNavigate();
	const {showAlert} = useContext(AlertContext);
	const {setBackdropVisible} = useBackdrop();
	
	useEffect(() => {
		const fetchGenus = async () => {
			if (id) {
				setIsLoading(true); // Start loading
				try {
					const data = await utils.callApi({
						endpoint: `hmpboapi/genus/${id}`,
					}, navigate, showAlert, setBackdropVisible);
					if (data) {
						setGenusName(data.name);
					}
				} catch (error) {
					console.error('Failed to fetch genus:', error);
				} finally {
					setIsLoading(false); // Stop loading
				}
			} else {
				setGenusName('');
			}
		};
		
		fetchGenus();
	}, [id, navigate, showAlert]);
	
	const handleSubmit = async (event) => {
		event.preventDefault();
		const apiEndpoint = id ? `hmpboapi/genus/${id}` : 'hmpboapi/genus';
		const method = id ? 'PATCH' : 'POST'; // Use PUT for update and POST for create
		const options = {
			endpoint: apiEndpoint,
			method: method,
			data: {
				name: genusName
			}
		};
		try {
			await utils.callApi(options, navigate, showAlert, setBackdropVisible);
			showAlert("Operation successful.", 'success');
			
			if (!id) {
				navigate('/genus');
			}
			// Handle successful operation (redirect or show a success message)
		} catch (error) {
			console.error('Failed to update:', error);
			// Handle errors appropriately
		}
	};
	
	if (isLoading) {
		return <Spinner animation="border" role="status">
			<span className="sr-only"></span>
		</Spinner>// Display a spinner or loading message
	}
	
	return (
		<div>
			<h2>{id ? 'Edit' : 'Create'} Genus</h2>
			<div className="d-flex justify-content-end">
				<Link to="/genus" className="btn btn-secondary mb-3">
					Back to List
				</Link>
			</div>
			
			<Form onSubmit={handleSubmit}>
				
				<Form.Group controlId="formGenusName" className="d-flex">
					<div className="col-sm-3">
						<Form.Label>Genus Name</Form.Label>
					</div>
					<div className="col-sm-8">
						<Form.Control type="text"
						              value={genusName}
						              onChange={(e) => setGenusName(e.target.value)}
						              required
						/></div>
				
				</Form.Group>
				
				<br/>
				<Button variant="primary" type="submit">
					{id ? 'Update Genus' : 'Create Genus'}
				</Button>
			</Form>
		</div>
	);
}

export default CreateGenus;
