import React from 'react';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';

import {BackdropProvider, useBackdrop} from './components/BackdropContext';
import Backdrop from './components/Backdrop';
import MainLayout from './components/internal/MainLayout';
import AppRoutes from './components/AppRoutes';
import LoginPage from './pages/external/LoginPage';
import ForgetPasswordPage from './pages/external/ForgetPasswordPage';
import ResetPasswordPage from './pages/external/ResetPasswordPage';
import {AlertProvider} from './components/AlertContext';
import './App.css';

const basicPages = [
	{path: "/login", component: LoginPage},
	{path: "/forget-password", component: ForgetPasswordPage},
	{path: "/reset-password", component: ResetPasswordPage},
	// Add more basic layout pages here
];

function App() {
	return (
		<AlertProvider>
			<BackdropProvider>
				<AppComponent/>
			</BackdropProvider>
		</AlertProvider>
	);
}

function AppComponent() {
	const {backdropVisible} = useBackdrop();
	
	return (
		<Router>
			{backdropVisible && <Backdrop/>}
			<Routes>
				{basicPages.map(({path, component: Component}) => (
					<Route key={path} path={path} element={<Component/>}/>
				))}
				<Route path="*" element={
					<MainLayout>
						<AppRoutes/>
					</MainLayout>
				}/>
			</Routes>
		</Router>
	);
}

export default App;



