/*
 * Copyright (C) 2023 Corsair M360, Inc - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import React, {useState, useEffect, useContext} from 'react';
import utils from '../../../utils/api';
import {Container, Button} from 'react-bootstrap';
import {Link, useNavigate} from 'react-router-dom';
import DynamicGrid from '../../../components/DynamicGrid';
import {AlertContext} from '../../../components/AlertContext';
import {useBackdrop} from '../../../components/BackdropContext';

function ListPage() {
	const [healthList, setList] = useState([]);
	const [isLoading, setIsLoading] = useState(false); // State for loading
	const {setBackdropVisible} = useBackdrop();
	const navigate = useNavigate();
	const {showAlert} = useContext(AlertContext);
	
	const fetchDataList = async () => {
		setIsLoading(true); // Start loading
		try {
			const data = await utils.callApi({
				method: 'POST',
				endpoint: 'hmpboapi/search/herb',
				data: {
					free: true
				}
			}, navigate, showAlert, setBackdropVisible);
			if (data) {
				setList(data);
			}
		} catch (error) {
			console.error('Failed to fetch list:', error);
			// Handle errors appropriately
		} finally {
			setIsLoading(false); // Stop loading
		}
	};
	
	const handleRefresh = () => {
		fetchDataList();
	};
	
	useEffect(() => {
		fetchDataList();
	}, []);
	
	const addTopTen = async (Id) => {
		let options = {
			method: 'PATCH',
			endpoint: `hmpboapi/top10/${Id}`,
			data: {
				top10: true
			}
		};
		const isConfirmed = window.confirm("Are you sure you want to make this record top 10?");
		
		if (isConfirmed) {
			try {
				await utils.callApi(options, null, showAlert, setBackdropVisible);
				fetchDataList();
			} catch (error) {
				console.error('Failed to delete:', error);
			}
		}
	};
	
	const handleDelete = async (Id) => {
		let options = {
			method: 'DELETE',
			endpoint: `hmpboapi/top10/${Id}`
		};
		const isConfirmed = window.confirm("Are you sure you want to delete this record?");
		
		if (isConfirmed) {
			try {
				await utils.callApi(options, null, showAlert, setBackdropVisible);
				fetchDataList();
			} catch (error) {
				console.error('Failed to delete:', error);
			}
		}
	};
	
	const fields = {
		options: {
			actionsWidth: '9%'
		},
		data: [
			{headerName: 'Type', fieldName: 'recordType'},
			{headerName: 'Is Top Ten', fieldName: 'top10'},
			{headerName: 'Scientific Name', fieldName: 'scientificName'},
			{headerName: 'Common Name', fieldName: 'commonName'},
			{headerName: 'Date Modified', fieldName: 'modified'}
		]
	};
	
	const actions = (item) => [
		!item.top10 && {
			label: 'Add to Top 10',
			icon: 'mdi mdi-plus', variant: 'secondary',
			action: (item) => addTopTen(item._id)
		},
		item.top10 === true && {
			label: 'Remove from Top 10',
			icon: 'mdi mdi-delete',
			variant: 'danger',
			action: (item) => handleDelete(item._id)
		}
	].filter(Boolean);
	
	return (
		<Container><h2>Top 10 Herbs</h2>
			
			<div className="d-flex">
				<div className="col-sm-6">
					<Button onClick={handleRefresh} className="mb-3" title="Refresh List">
						<i className="mdi mdi-refresh"></i>
					</Button>
				</div>
			
			</div>
			
			<p>List of all the free herbs and choose the top ten</p>
			
			{isLoading ? (
				<p>Loading...</p> // Display loading message
			) : (
				<DynamicGrid data={healthList} fields={fields} actions={actions}/>
			)}
		
		</Container>
	);
	
}

export default ListPage;
