/*
 * Copyright (C) 2021 Corsair M360, Inc - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import React, { createContext, useState, useContext } from 'react';

const BackdropContext = createContext();

export const useBackdrop = () => useContext(BackdropContext);

export const BackdropProvider = ({ children }) => {
	const [backdropVisible, setBackdropVisible] = useState(false);
	
	return (
		<BackdropContext.Provider value={{ backdropVisible, setBackdropVisible }}>
			{children}
		</BackdropContext.Provider>
	);
};
