/*
 * Copyright (C) 2023 Corsair M360, Inc - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import React, {useState, useEffect, useContext} from 'react';
import utils from '../../../utils/api';
import {Container, Button} from 'react-bootstrap';
import {Link, useNavigate} from 'react-router-dom';
import DynamicGrid from '../../../components/DynamicGrid';
import {AlertContext} from '../../../components/AlertContext';
import {useBackdrop} from '../../../components/BackdropContext';

function ListPage() {
	const [healthList, setList] = useState([]);
	const [isLoading, setIsLoading] = useState(false); // State for loading
	const {setBackdropVisible} = useBackdrop();
	const navigate = useNavigate();
	const {showAlert} = useContext(AlertContext);
	
	const fetchDataList = async () => {
		setIsLoading(true); // Start loading
		try {
			const data = await utils.callApi({
				method: 'GET',
				endpoint: 'hmpboapi/recordType'
			}, navigate, showAlert, setBackdropVisible);
			if (data) {
				setList(data);
			}
		} catch (error) {
			console.error('Failed to fetch list:', error);
			// Handle errors appropriately
		} finally {
			setIsLoading(false); // Stop loading
		}
	};
	
	const handleRefresh = () => {
		fetchDataList();
	};
	
	useEffect(() => {
		fetchDataList();
	}, []);
	
	const handleEdit = (Id) => {
		navigate(`/edit-record-type/${Id}`);
	};
	
	const handleDelete = async (Id) => {
		let options = {
			endpoint: `hmpboapi/recordType/${Id}`,
			method: 'DELETE'
		};
		const isConfirmed = window.confirm("Are you sure you want to delete this record?");
		
		if (isConfirmed) {
			try {
				await utils.callApi(options, null, showAlert, setBackdropVisible);
				fetchDataList();
				
			} catch (error) {
				console.error('Failed to delete:', error);
			}
		}
		
	};
	
	const fields = [
		{headerName: 'Title', fieldName: 'title'},
		{headerName: 'Name', fieldName: 'name'},
		// Add other fields as necessary
	];
	
	const access = {
		delete: utils.checkAccess({method: 'DELETE', route: '/recordType/:id'}),
		update: utils.checkAccess({method: 'PATCH', route: '/recordType/:id'})
	};
	
	const actions = (item) => [
		(access.update) && {
			label: 'Edit',
			icon: 'mdi mdi-pencil',
			variant: 'secondary', action: (item) => handleEdit(item._id)
		},
		
		(access.delete) && {
			label: 'Delete',
			icon: 'mdi mdi-delete',
			variant: 'danger', action: (item) => handleDelete(item._id)
		}
	].filter(Boolean);
	
	return (
		<Container><h2>Record Type</h2>
			
			<div className="d-flex">
				<div className="col-sm-6">
					<Button onClick={handleRefresh} className="mb-3" title="Refresh List">
						<i className="mdi mdi-refresh"></i>
					</Button>
				</div>
				<div className="col-sm-6 d-flex align-items-center justify-content-end">
					<div className="d-flex justify-content-end">
						<Link to="/create-record-type" className="btn btn-secondary mb-3">
							Add New</Link>
					</div>
				</div>
			</div>
			
			{isLoading ? (
				<p>Loading...</p> // Display loading message
			) : (
				<DynamicGrid data={healthList} fields={fields} actions={actions}/>
			)}
		
		</Container>
	);
	
}

export default ListPage;
