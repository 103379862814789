/*
 * Copyright (C) 2023 Corsair M360, Inc - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

// EditProfilePage.js
import React, {useState, useEffect, useContext} from 'react';
import {Container, Row, Col, Form, Button, Spinner, Tabs, Tab} from 'react-bootstrap';
import utils from '../../../utils/api';
import {useNavigate} from 'react-router-dom';
import {AlertContext} from '../../../components/AlertContext';
import {useBackdrop} from '../../../components/BackdropContext';

function EditProfilePage() {
	const [isLoading, setIsLoading] = useState(false); // State for loading
	const navigate = useNavigate();
	const {showAlert} = useContext(AlertContext);
	const {setBackdropVisible} = useBackdrop();
	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [email, setEmail] = useState('');
	const [username, setUsername] = useState('');
	
	const [currentPassword, setCurrentPassword] = useState('');
	const [newPassword, setNewPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	
	const handleChangePassword = async (event) => {
		event.preventDefault();
		let opts = {
			'endpoint': 'user/password/change',
			'method': 'POST',
			'data': {
				'old': currentPassword,
				'password': newPassword,
				'confirmation': confirmPassword,
			}
		};
		utils.callApi(opts, navigate, showAlert)
			.then(result => {
				showAlert("Operation successful.", 'success');
			}).catch(error => {
			console.error(error);
		});
	};
	
	const fetchProfile = async () => {
		setIsLoading(true); // Start loading
		try {
			const data = await utils.callApi({
				endpoint: 'user'
			}, navigate, showAlert);
			setFirstName(data.user.firstName);
			setLastName(data.user.lastName);
			setEmail(data.user.email);
			setUsername(data.user.username);
		} catch (error) {
			console.error('Failed to fetch profile:');
			console.error(error);
			// utils.forceLogout(navigate);
		} finally {
			setIsLoading(false);
		}
	};
	
	useEffect(() => {
		// Load the current user's profile data
		fetchProfile();
	}, []);
	
	const profileSubmit = async (event) => {
		event.preventDefault();
		
		const options = {
			endpoint: 'user',
			method: 'PUT',
			data: {
				firstName: firstName,
				lastName,
				email,
				username,
				profile: {}
			},
		};
		try {
			await utils.callApi(options, navigate, showAlert, setBackdropVisible);
			showAlert("Operation successful.", 'success');
			// Handle successful profile update
		} catch (error) {
			console.error('Failed to update profile:', error);
			// Handle errors appropriately
		}
	};
	
	if (isLoading) {
		return <Spinner animation="border" role="status">
			<span className="sr-only"></span>
		</Spinner>// Display a spinner or loading message
	}
	
	return (
		<Container>
			
			<Row className="justify-content-md-center">
				<Col md={10}>
					<h2 className="text-center mt-4">User Settings</h2>
					<Tabs defaultActiveKey="editProfile" id="user-settings-tabs" className="mb-3">
						<Tab eventKey="editProfile" title="Edit Profile">
							
							<h3 className="mt-4">Edit Profile</h3>
							
							<Form onSubmit={profileSubmit} className="mt-4">
								<Row>
									<Col md={6}>
										<Form.Group controlId="formBasicFirstName">
											<Form.Label>First Name</Form.Label>
											<Form.Control
												type="text"
												placeholder="Enter first name"
												value={firstName}
												onChange={(e) => setFirstName(e.target.value)}
												required
											/>
										</Form.Group>
									</Col>
									<Col md={6}>
										<Form.Group controlId="formBasicLastName">
											<Form.Label>Last Name</Form.Label>
											<Form.Control
												type="text"
												placeholder="Enter last name"
												value={lastName}
												onChange={(e) => setLastName(e.target.value)}
												required
											/>
										</Form.Group>
									</Col>
								</Row>
								<Row>
									<Col md={6}>
										<Form.Group controlId="formBasicEmail">
											<Form.Label>Email</Form.Label>
											<Form.Control
												type="email"
												placeholder="Enter email"
												value={email}
												onChange={(e) => setEmail(e.target.value)}
												required
											/>
										</Form.Group>
									</Col>
									<Col md={6}>
										<Form.Group controlId="formBasicUsername">
											<Form.Label>Username</Form.Label>
											<Form.Control
												type="text"
												placeholder="Enter username"
												value={username}
												onChange={(e) => setUsername(e.target.value)}
												required
											/>
										</Form.Group>
									</Col>
								</Row>
								<Row>
									<Col md={6}>
										<Button variant="primary" type="submit" className="w-100 mt-3">
											Update Profile
										</Button>
									</Col>
								</Row>
							
							</Form>
						
						</Tab>
						<Tab eventKey="changePassword" title="Change Password">
							<Form onSubmit={handleChangePassword} className="mt-4">
								<Form.Group controlId="formCurrentPassword">
									<Form.Label>Current Password</Form.Label>
									<Form.Control
										type="password"
										placeholder="Enter current password"
										value={currentPassword}
										onChange={(e) => setCurrentPassword(e.target.value)}
										required
									/>
								</Form.Group>
								<Form.Group controlId="formNewPassword">
									<Form.Label>New Password</Form.Label>
									<Form.Control
										type="password"
										placeholder="Enter new password"
										value={newPassword}
										onChange={(e) => setNewPassword(e.target.value)}
										required
									/>
								</Form.Group>
								<Form.Group controlId="formConfirmPassword">
									<Form.Label>Confirm New Password</Form.Label>
									<Form.Control
										type="password"
										placeholder="Confirm new password"
										value={confirmPassword}
										onChange={(e) => setConfirmPassword(e.target.value)}
										required
									/>
								</Form.Group>
								
								<Row>
									<Col md={6}>
										<Button variant="primary" type="submit" className="w-100 mt-3">
											Change Password
										</Button>
									</Col>
								</Row>
							
							</Form>
						</Tab>
					</Tabs>
				</Col>
			</Row>
		
		</Container>
	);
}

export default EditProfilePage;

