/*
 * Copyright (C) 2023 Corsair M360, Inc - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import React from 'react';

const Backdrop = () => (
	<div className="backdrop">
	
	</div>
);

export default Backdrop;
