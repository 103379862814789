/*
 * Copyright (C) 2023 Corsair M360, Inc - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import React, {useState, useEffect, useContext} from 'react';
import {NavLink} from 'react-router-dom';
import utils from '../../utils/api';

function LeftMenu() {
	
	const menuConfig = {
		recordType: {
			list: utils.checkAccess({method: 'GET', route: '/recordType'}),
			add: utils.checkAccess({method: 'POST', route: '/recordType'})
		},
		herbs: {
			list: utils.checkAccess({method: 'GET', route: '/herb'}),
			add: utils.checkAccess({method: 'POST', route: '/herb'})
		},
		genus: {
			show: true
		},
	};
	
	const [access, setAccess] = useState(menuConfig);
	
	useEffect(() => {
		const fetchAccess = async () => {
			setAccess(menuConfig);
		};
		
		fetchAccess();
		
	}, []);
	
	
	return (
		<div className="menu-wrapper">
			<nav>
				<ul className="custom-menu">
					
					<li>
						<NavLink activeClassName="active-link" to="/record-type">
							List Record Type</NavLink>
						{access.recordType.add && (
							<ul>
								<li>
									<NavLink activeClassName="active-link" to="/create-record-type">
										Add Record Type</NavLink>
								</li>
							</ul>
						)}
					</li>
					
					<li>
						<NavLink activeClassName="active-link" to="/herbs">List Herbs</NavLink>
						
						{access.herbs.add && (
							<ul>
								<li>
									<NavLink activeClassName="active-link" to="/create-herb">Add Herb</NavLink>
								</li>
							</ul>
						)}
					</li>
					
					<li><NavLink activeClassName="active-link" to="/genus">List Genus</NavLink>
						<ul>
							<li>
								<NavLink activeClassName="active-link" to="/create-genus">
									Add Genus</NavLink></li>
						</ul>
					</li>
					
					<li><NavLink activeClassName="active-link" to="/category">
						List Category</NavLink>
						<ul>
							<li><NavLink activeClassName="active-link" to="/create-category">
								Add Category</NavLink></li>
						</ul>
					</li>
					<li><NavLink activeClassName="active-link" to="/health-issues">List Health Indications</NavLink>
						<ul>
							<li><NavLink activeClassName="active-link" to="/create-health-issue">Add Health
								Indication</NavLink></li>
						</ul>
					</li>
					<li><NavLink activeClassName="active-link" to="/herb-actions">List Herb Actions</NavLink>
						<ul>
							<li><NavLink activeClassName="active-link" to="/create-herb-action">Add Herb
								Action</NavLink></li>
						</ul>
					</li>
					
					<li>
						<NavLink activeClassName="active-link" to="/sources">
							List Sources</NavLink>
						<ul>
							<li>
								<NavLink activeClassName="active-link" to="/create-source">
									Add Source</NavLink></li>
						</ul>
					</li>
					
					<li>
						<NavLink activeClassName="active-link" to="/top-10">
							List Top 10</NavLink>
					</li>
				</ul>
				<br/>
				<br/>
			</nav>
		</div>
	);
}

export default LeftMenu;