
/*
 * Copyright (C) 2023 Corsair M360, Inc - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import React, {useEffect, useContext} from 'react';
import LeftMenu from './LeftMenu';
import TopBar from '../TopBar';
import Footer from '../Footer';
import './MainLayout.css'; // For additional custom styles

import {Alert} from 'react-bootstrap'; // Import Alert from react-bootstrap
import {AlertContext} from '../AlertContext';

function MainLayout({children}) {
	const {alert, hideAlert} = useContext(AlertContext);
	
	useEffect(() => {
		if (alert.show) {
			const timer = setTimeout(() => {
				hideAlert();
			}, 5000); // Hide the alert after (4 seconds)
			
			return () => clearTimeout(timer); // Clear the timer if the component unmounts
		}
	}, [alert, hideAlert]);
	
	return (
		<div className="container-fluid">
			<div className="row">
				<TopBar/>
				
				<div className="col-md-2 left-section">
					<LeftMenu/>
				</div>
				<div className="col-md-10 no-padding">
					<div className="member-content">
						{alert.show && (
							<Alert variant={alert.variant} onClose={hideAlert} dismissible>
								{alert.messages.map((message, index) => (
									<p key={index}>{message}</p>
								))}
							</Alert>
						)}
						<div>{children}</div>
					</div>
				</div>
			</div>
			<Footer></Footer>
		</div>
	);
}

export default MainLayout;