/*
 * Copyright (C) 2023 Corsair M360, Inc - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import React, {useState, useEffect, useContext} from 'react';
import utils from '../../../utils/api';
import {Container, Form, Button, Spinner, Row} from 'react-bootstrap';
import {Link, useNavigate, useParams} from 'react-router-dom';
import {AlertContext} from '../../../components/AlertContext';
import {useBackdrop} from '../../../components/BackdropContext';

function CreateEntry() {
	// State for form fields
	const [herbIdState, setHerbIdState] = useState('');
	const [herbName, setHerbName] = useState('');
	const [authors, setAuthors] = useState('');
	const [year, setYear] = useState('');
	const [linkUrl, setLinkUrl] = useState('');
	const [linkCaption, setLinkCaption] = useState('');
	const [referenceID, setReferenceID] = useState('');
	const [pubMedID, setPubMedID] = useState('');
	
	const [reference, setReference] = useState('');
	const [summary, setSummary] = useState('');
	const [sourceOptions, setSourceOptions] = useState([]);
	const [selectedSource, setSelectedSource] = useState('');
	const [categoryOptions, setCategoryOptions] = useState([]);
	const [selectedCategory, setSelectedCategory] = useState('');
	
	// Loading state
	const [isLoading, setIsLoading] = useState(false);
	const navigate = useNavigate();
	const {showAlert} = useContext(AlertContext);
	const {setBackdropVisible} = useBackdrop();
	const {id, herbId} = useParams();
	
	useEffect(() => {
		setIsLoading(true);
		
		const fetchHerb = async (id) => {
			try {
				const herb = await utils.callApi({
					endpoint: `hmpboapi/herb/${id}`
				}, null, showAlert);
				if (herb) {
					setHerbName(herb.scientificName);
				} else {
					console.warn(herb);
				}
			} catch (error) {
				console.error('Failed to fetch herb:', error);
				navigate(`/list-entry/${id}`);
			}
		};
		
		const fetchSources = async () => {
			try {
				const sources = await utils.callApi({
					endpoint: 'hmpboapi/source'
				}, null, showAlert);
				if (sources) {
					setSourceOptions(sources);
				} else {
					console.warn(sources);
				}
			} catch (error) {
				console.error('Failed to fetch sources:', error);
			}
		};
		
		const fetchCategories = async () => {
			try {
				let listCats = [];
				const categories = await utils.callApi({
					endpoint: 'hmpboapi/category'
				}, null, showAlert);
				
				if (categories) {
					categories.forEach(function (one) {
						if (one.sub) {
							for (let k in one.sub) {
								listCats.push(
									{
										label: `${one.name} - ${one.sub[k]}`,
										name: one.sub[k]
									});
							}
						}
					});
				}
				
				setCategoryOptions(listCats);
			} catch (error) {
				console.error('Failed to fetch categories:', error);
			}
		};
		
		const fetchEntry = async () => {
			try {
				const data = await utils.callApi({
					endpoint: `hmpboapi/entry/${id}`
				}, navigate, showAlert, setBackdropVisible);
				
				if (data) {
					setAuthors(data.authors);
					setYear(data.year);
					setReference(data.reference);
					setLinkUrl(data.linkUrl);
					setLinkCaption(data.linkCaption);
					setReferenceID(data.referenceID);
					setPubMedID(data.PubMedID);
					setSummary(data.summary);
					setSelectedSource(data.source);
					setSelectedCategory(data.category.name);
					
					if (data.herb) {
						setHerbIdState(data.herb.id); // Update the state with the fetched herbId
					}
					
					return data.herb;
				} else {
					return {};
				}
				
			} catch (error) {
				console.error('Failed to fetch entry:', error);
				showAlert("Failed to fetch entry.", 'danger');
			}
		};
		
		const getEntry = async () => {
			if (id) {
				const herb = await fetchEntry();
				if (herb && herb.id) {
					await fetchHerb(herb.id);
				} else {
					console.error("Missing Herb info");
				}
			} else {
				setHerbIdState(herbId);
				await fetchHerb(herbId);
			}
		};
		
		const fetchData = async () => {
			await Promise.all([
				fetchSources(),
				fetchCategories(),
				getEntry()
			]);
			
			setIsLoading(false); // Stop loading after all fetch operations
		};
		
		fetchData();
		
	}, [id, herbId, navigate, showAlert, setBackdropVisible]);
	
	const handleSubmit = async (event) => {
		event.preventDefault();
		setIsLoading(true);
		setBackdropVisible(true);
		
		const entryData = {
			authors,
			reference,
			year,
			linkUrl,
			linkCaption,
			referenceID,
			PubMedID: pubMedID,
			summary,
			source: selectedSource,
			category: {
				name: selectedCategory
			}
		};
		
		try {
			const apiEndpoint = id ? `hmpboapi/entry/${id}` : `hmpboapi/entry/${herbId}`;
			const method = id ? 'PATCH' : 'POST';
			await utils.callApi({
				endpoint: apiEndpoint,
				method,
				data: entryData
			}, navigate, showAlert);
			showAlert("Entry saved successfully.", 'success');
			if (!id) {
				navigate(`/list-entry/${herbIdState}`);
			}
			
		} catch (error) {
			console.error(error);
			showAlert("Failed to save entry.", 'danger');
		} finally {
			setIsLoading(false);
			setBackdropVisible(false);
		}
	};
	
	if (isLoading) {
		return <Spinner animation="border" role="status">
			<span className="sr-only"></span>
		</Spinner>// Display a spinner or loading message
	}
	
	return (
		<Container>
			<h2>{id ? 'Edit' : 'Create'} Entry for "{herbName}"</h2>
			
			<div className="d-flex justify-content-end">
				<Link to={`/list-entry/${herbIdState}`} className="btn btn-secondary mb-3">
					Back to List
				</Link>
			
			</div>
			
			<Form onSubmit={handleSubmit} className="input-table">
				{/* Form fields */}
				{/* ... existing fields ... */}
				<Form.Group as={Row} controlId="formCategory" className="d-flex">
					
					<div className="col-sm-2">
						<Form.Label>Category</Form.Label>
					</div>
					<div className="col-sm-9">
						<Form.Control as="select" value={selectedCategory} required
						              onChange={e => setSelectedCategory(e.target.value)}>
							<option value="">Select Category</option>
							{categoryOptions.map((category, index) => (
								<option key={index} value={category.name}>
									{category.label}</option>
							))}
						</Form.Control>
					</div>
				</Form.Group>
				
				<Form.Group as={Row} controlId="formSource" className="d-flex even-row">
					<div className="col-sm-2">
						<Form.Label>Source</Form.Label>
					</div>
					<div className="col-sm-9">
						<Form.Control as="select" value={selectedSource}
						              onChange={e => setSelectedSource(e.target.value)}>
							<option value="">Select Source</option>
							{sourceOptions.map((source, index) => (
								<option key={index} value={source.id}>{source.name}</option>
							))}
						</Form.Control>
					</div>
				</Form.Group>
				
				<Form.Group as={Row} controlId="formAuthors" className="d-flex">
					<div className="col-sm-2">
						<Form.Label>Authors</Form.Label>
					</div>
					<div className="col-sm-9">
						<Form.Control type="text"
						              value={authors}
						              onChange={(e) => setAuthors(e.target.value)}
						/>
					</div>
				
				</Form.Group>
				
				<Form.Group as={Row} controlId="formLinkCaption" className="d-flex even-row">
					<div className="col-sm-2">
						<Form.Label>Link Caption</Form.Label>
					</div>
					<div className="col-sm-9">
						<Form.Control type="text"
						              value={linkCaption}
						              onChange={(e) => setLinkCaption(e.target.value)}
						/>
					</div>
				
				</Form.Group>
				
				<Form.Group as={Row} controlId="formLinkUrl" className="d-flex">
					<div className="col-sm-2">
						<Form.Label>Link Url</Form.Label>
					</div>
					<div className="col-sm-9">
						<Form.Control type="text"
						              value={linkUrl}
						              onChange={(e) => setLinkUrl(e.target.value)}
						/>
						{linkUrl &&
							<a target="_blank" href={linkUrl}>View Link</a>
						}
					
					</div>
				
				</Form.Group>
				
				<Form.Group as={Row} controlId="formYear" className="d-flex even-row">
					<div className="col-sm-2">
						<Form.Label>Year</Form.Label>
					</div>
					<div className="col-sm-9">
						<Form.Control type="text"
						              value={year}
						              onChange={(e) => setYear(e.target.value)}
						/>
					</div>
				
				</Form.Group>
				
				<Form.Group as={Row} controlId="formReference" className="d-flex">
					<div className="col-sm-2">
						<Form.Label>Reference</Form.Label>
					</div>
					<div className="col-sm-9">
						<Form.Control type="text"
						              value={reference}
						              onChange={(e) => setReference(e.target.value)}
						/>
					</div>
				
				</Form.Group>
				
				<Form.Group as={Row} controlId="formPubMedID" className="d-flex even-row">
					<div className="col-sm-2">
						<Form.Label>PubMedID</Form.Label>
					</div>
					<div className="col-sm-9">
						<Form.Control type="text"
						              value={pubMedID}
						              onChange={(e) => setPubMedID(e.target.value)}
						/>
					</div>
				
				</Form.Group>
				
				<Form.Group as={Row} controlId="formSummary" className="d-flex">
					<div className="col-sm-2">
						<Form.Label>Summary</Form.Label>
					</div>
					<div className="col-sm-9">
						<Form.Control type="text"
						              as="textarea"
						              rows={3} // Sets the height to accommodate 3 lines
						              value={summary}
						              onChange={(e) => setSummary(e.target.value)}
						/>
					</div>
				
				</Form.Group>
				
				<Button variant="primary" type="submit" disabled={isLoading}>
					{id ? 'Update' : 'Create'} Entry
				</Button>
			</Form>
		
		</Container>
	);
}

export default CreateEntry;
