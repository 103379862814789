/*
 * Copyright (C) 2023 Corsair M360, Inc - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

// BasicLayout.js
import React, {useEffect, useContext} from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import './BasicLayout.css';
import {Alert} from 'react-bootstrap'; // Import Alert from react-bootstrap
import {AlertContext} from '../AlertContext';
import TopBar from "../TopBar";
import Footer from '../Footer';

function BasicLayout({children}) {
	const {alert, hideAlert} = useContext(AlertContext);
	
	return (
		<div className="container-fluid basic-layout">
			<div className="row">
				<TopBar/>
				
				<div className="no-padding">
					<div className="member-content">
						
						<Row className="justify-content-md-center">
							<Col md={10}>
								<div>
									{alert.show && (
										<Alert variant={alert.variant} onClose={hideAlert} dismissible>
											{alert.messages.map((message, index) => (
												<p key={index}>{message}</p>
											))}
										</Alert>
									)}
									
									{children}
								</div>
							</Col>
						</Row>
					
					</div>
				</div>
				<br/><br/>
				
			</div>
			
			<Footer/>
		
		</div>
	
	);
}

export default BasicLayout;
