/*
 * Copyright (C) 2023 Corsair M360, Inc - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import React, {useState, useEffect, useContext} from 'react';
import {NavLink} from 'react-router-dom';
import withBasicLayout from '../../components/external/withBasicLayout';
import {Container, Col, Form, Button, Row} from 'react-bootstrap';
import utils from '../../utils/api';
import {useNavigate} from 'react-router-dom';
import {AlertContext} from '../../components/AlertContext';
import {useBackdrop} from '../../components/BackdropContext';

function PasswordPage() {
	
	const [username, setUsername] = useState('');
	
	const navigate = useNavigate();
	const {showAlert, hideAlert} = useContext(AlertContext);
	const {setBackdropVisible} = useBackdrop();
	
	const setUser = async () => {
		let options = {
			endpoint: 'user',
			method: 'GET',
		};
		try {
			const result = await utils.callApi(options, null, showAlert, setBackdropVisible, AlertContext);
			// localStorage.setItem('user', JSON.stringify(result.user));
			localStorage.setItem('acl', JSON.stringify(result.acl));
			navigate('/home');
		} catch (error) {
			console.error(error);
		}
	};
	
	const handleSubmit = async (event) => {
		event.preventDefault();
		let options = {
			endpoint: 'user/password/forgot',
			method: 'POST',
			data: {
				"auto_register_flow": "main_flow",
				username
			}
		};
		try {
			hideAlert();
			const result = await utils.callApi(options, null, showAlert, setBackdropVisible, AlertContext);
			console.log(result);
		} catch (error) {
			console.error(error);
		}
	};
	
	return (
		<Container>
			<div className="justify-content-md-center">
				<Col md={12}>
					<Form onSubmit={handleSubmit} className="mt-5 input-table">
						<h2 className="text-center">Forget Password</h2>
						<Form.Group as={Row} controlId="formBasicUsername">
							<Form.Label>Username</Form.Label>
							<Form.Control
								type="text"
								placeholder="Enter username"
								value={username}
								onChange={(e) => setUsername(e.target.value)}
								required
							/>
						</Form.Group>
						
						<br/>
						<Form.Group as={Row}>
							<Col md={6}>
								<Button variant="primary" type="submit" className="w-100">
									Send Link
								</Button>
							</Col>
							<Col md={6}>
							
							</Col>
						</Form.Group>
					
					</Form>
				</Col>
			</div>
		</Container>
	);
}

export default withBasicLayout(PasswordPage);