import React from 'react';
import {Routes, Route} from 'react-router-dom';
import HomePage from '../pages/internal/HomePage';

import ListRecordType from '../pages/internal/records/ListRecordType';

import HerbsListPage from '../pages/internal/records/ListHerbPage';
import EntryListPage from '../pages/internal/records/ListEntryPage';
import CategoryListPage from '../pages/internal/records/ListCategory';
import ListHealthIssues from '../pages/internal/records/ListHealthIssues';
import ListHerbActions from '../pages/internal/records/ListHerbActions';
import ListGenus from '../pages/internal/records/ListGenus';
import ListSources from '../pages/internal/records/ListSources';
import ListTopTen from '../pages/internal/records/ListTop10';

import CreateCategory from '../pages/internal/records/CreateCategory';
import CreateType from '../pages/internal/records/CreateType';
import CreateEntryPage from '../pages/internal/records/CreateEntryPage';
import CreateHerbPage from '../pages/internal/records/CreateHerbPage';
import CreateHealthIssue from '../pages/internal/records/CreateHealthIssue';
import CreateHerbAction from '../pages/internal/records/CreateHerbAction';
import CreateGenus from '../pages/internal/records/CreateGenus';
import CreateSource from '../pages/internal/records/CreateSource';

import EditProfilePage from "../pages/internal/user/EditProfilePage";

function AppRoutes() {
	return (
		<Routes>
			<Route path="/edit-profile" element={<EditProfilePage/>}/>
			
			<Route path="/" element={<HomePage/>}/>
			<Route path="/home" element={<HomePage/>}/>
			
			<Route path="/record-type" element={<ListRecordType/>}/>
			<Route path="/create-record-type" element={<CreateType/>}/>
			<Route path="/edit-record-type/:id" element={<CreateType/>}/>
			
			<Route path="/herbs" element={<HerbsListPage/>}/>
			<Route path="/create-herb" element={<CreateHerbPage/>}/>
			<Route path="/edit-herb/:id" element={<CreateHerbPage/>}/>
			
			<Route path="/list-entry/:herbId" element={<EntryListPage/>}/>
			<Route path="/add-entry/:herbId" element={<CreateEntryPage/>}/>
			<Route path="/edit-entry/:id" element={<CreateEntryPage/>}/>
			
			<Route path="/health-issues" element={<ListHealthIssues/>}/>
			<Route path="/edit-health-issue/:id" element={<CreateHealthIssue/>}/>
			<Route path="/create-health-issue" element={<CreateHealthIssue/>}/>
			
			<Route path="/herb-actions" element={<ListHerbActions/>}/>
			<Route path="/create-herb-action" element={<CreateHerbAction/>}/>
			<Route path="/edit-herb-action/:id" element={<CreateHerbAction/>}/>
			
			<Route path="/genus" element={<ListGenus/>}/>
			<Route path="/create-genus" element={<CreateGenus/>}/>
			<Route path="/edit-genus/:id" element={<CreateGenus/>}/>
			
			<Route path="/sources" element={<ListSources/>}/>
			<Route path="/create-source" element={<CreateSource/>}/>
			<Route path="/edit-source/:id" element={<CreateSource/>}/>
			
			<Route path="/category" element={<CategoryListPage/>}/>
			<Route path="/edit-category/:id" element={<CreateCategory/>}/>
			<Route path="/create-category" element={<CreateCategory/>}/>
			
			<Route path="/top-10" element={<ListTopTen/>}/>
		</Routes>
	
	);
}

export default AppRoutes;
