/*
 * Copyright (C) 2023 Corsair M360, Inc - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import React, {useState, useEffect, useContext} from 'react';
import {Form, Button, Spinner} from 'react-bootstrap';
import {useParams} from 'react-router-dom';
import {Link} from 'react-router-dom';
import utils from "../../../utils/api";
import {useNavigate} from 'react-router-dom';
import {AlertContext} from '../../../components/AlertContext';
import {useBackdrop} from '../../../components/BackdropContext';
import DynamicGrid from '../../../components/DynamicGrid';

function CreateAction() {
	const [isLoading, setIsLoading] = useState(false); // State for loading
	const [name, setName] = useState('');
	const {id} = useParams(); // Get the id param from the route
	const navigate = useNavigate();
	const {showAlert} = useContext(AlertContext);
	const {setBackdropVisible} = useBackdrop();
	
	const [herbList, setList] = useState([]);
	
	const fetchName = async () => {
		setIsLoading(true); // Start loading
		try {
			const data = await utils.callApi({
				endpoint: `hmpboapi/herbAction/${id}`
			}, navigate, showAlert, setBackdropVisible);
			if (data) {
				setName(data.name);
				return data.name;
			}
		} catch (error) {
			console.error('Failed to fetch genus:', error);
		} finally {
			setIsLoading(false); // Stop loading
		}
	};
	
	const fetchHerbs = async (myname) => {
		const option = {
			method: 'POST',
			endpoint: `hmpboapi/search/herb`,
			data: {
				herbAction: myname
			}
		};
		
		try {
			const data = await utils.callApi(option, null, null, setBackdropVisible);
			if (data) {
				setList(data);
			}
		} catch (error) {
			console.error('Failed to fetch herbs:', error);
		}
	};
	
	const fetchData = async () => {
		let myname = await fetchName();
		if (myname) {
			await fetchHerbs(myname);
		}
	};
	
	useEffect(() => {
		if (id) {
			fetchData();
		} else {
			setName('');
			setList([]);
		}
	}, [id, navigate, showAlert]);
	
	const handleSubmit = async (event) => {
		event.preventDefault();
		const apiEndpoint = id ? `hmpboapi/herbAction/${id}` : 'hmpboapi/herbAction';
		const method = id ? 'PATCH' : 'POST'; // Use PUT for update and POST for create
		const options = {
			'endpoint': apiEndpoint,
			method: method,
			data: {
				name: name
			}
		};
		try {
			await utils.callApi(options, navigate, showAlert, setBackdropVisible);
			showAlert("Operation successful.", 'success');
			
			if (!id) {
				navigate('/herb-actions');
			}
			// Handle successful operation (redirect or show a success message)
		} catch (error) {
			console.error('Failed to update:', error);
			// Handle errors appropriately
		}
	};
	
	const fields = [
		{headerName: 'Live', width: '5%', fieldName: 'published', headerTitle: 'Published'},
		{headerName: 'Type', width: '5%', fieldName: 'recordType'},
		{headerName: 'Scientific Name', fieldName: 'scientificName'},
		{headerName: 'Common Name', fieldName: 'commonName'},
		{headerName: 'Date Modified', fieldName: 'modified', className: "wordBreak"},
	];
	
	const handleEdit = (Id) => {
		navigate(`/edit-herb/${Id}`);
	};
	
	const actions = [
		{
			label: 'Edit',
			icon: 'mdi mdi-pencil',
			variant: 'secondary',
			action: (item) => handleEdit(item._id)
		}
	];
	
	if (isLoading) {
		return <Spinner animation="border" role="status">
			<span className="sr-only"></span>
		</Spinner>// Display a spinner or loading message
	}
	
	return (
		<div>
			<h2>{id ? 'Edit' : 'Create'} Action Name</h2>
			<div className="d-flex justify-content-end">
				<Link to="/herb-actions" className="btn btn-secondary mb-3">
					Back to List
				</Link>
			</div>
			
			<Form onSubmit={handleSubmit}>
				
				<Form.Group controlId="formGenusName" className="d-flex">
					<div className="col-sm-3">
						<Form.Label>Herb Action Name</Form.Label>
					</div>
					<div className="col-sm-8">
						<Form.Control type="text"
						              value={name}
						              onChange={(e) => setName(e.target.value)}
						              required
						/></div>
				
				</Form.Group>
				
				<br/>
				<Button variant="primary" type="submit">
					{id ? 'Update' : 'Create'} Herb Action
				</Button>
			</Form>
			
			{(herbList.length > 0) &&
				<div>
					<br/>
					<hr/>
					<br/>
					List of Herbs that reference this herb action:
					
					<DynamicGrid data={herbList} fields={fields} actions={actions}/>
					<br/>
				</div>
			}
		
		</div>
	);
}

export default CreateAction;
