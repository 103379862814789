/*
 * Copyright (C) 2021 Corsair M360, Inc - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import React, {useState, useEffect, useContext} from 'react'; // Import useState and useEffect
import {Navbar, Nav, Dropdown, Container} from 'react-bootstrap';
import utils from '../utils/api';
import {Link} from 'react-router-dom';
import {useNavigate} from 'react-router-dom';
import {useBackdrop} from '../components/BackdropContext';
import {AlertContext} from "./AlertContext";
import logo from '../images/logo-hg40.png';

function TopBar() {
	const [userFirstName, setUserFirstName] = useState('');
	const navigate = useNavigate();
	const {setBackdropVisible} = useBackdrop();
	
	const {showAlert, hideAlert} = useContext(AlertContext);
	
	const getUser = async () => {
		let options = {
			endpoint: 'user',
			method: 'GET',
		};
		try {
			const result = await utils.callApi(options, navigate, showAlert, setBackdropVisible, AlertContext);
			//console.log(result);
		} catch (error) {
			console.error(error);
		}
	};
	
	useEffect(() => {
		let storedUser;
		try {
			const storedToken = JSON.parse(localStorage.getItem('token'));
			if (storedToken && storedToken.access_token) {
				getUser();
				storedUser = JSON.parse(localStorage.getItem('user'));
			}
			
		} catch (e) {
			console.error(e);
		}
		
		if (storedUser && storedUser.firstName) {
			setUserFirstName(storedUser.firstName);
		} else {
			utils.forceLogout(navigate);
			//navigate('/login');
		}
	}, []);
	
	const handleLogout = async () => {
		const isConfirmed = window.confirm("Are you sure you want to logout?");
		
		if (isConfirmed) {
			try {
				const storedtoken = JSON.parse(localStorage.getItem('token'));
				if (storedtoken && storedtoken.refresh_token) {
					const options = {
						endpoint: 'logout',
						method: 'get',
						params: {
							'refresh': storedtoken.refresh_token
						}
					};
					await utils.callApi(options, navigate, null, setBackdropVisible);
					utils.forceLogout(navigate);
				} else {
					utils.forceLogout(navigate);
				}
			} catch (error) {
				// Optionally handle logout failure
				utils.forceLogout(navigate);
			}
		}
		
	};
	
	return (
		<Container className="top-nav-bar">
			<Navbar expand="lg">
				<Navbar.Brand as={Link} to="/home">
					<img src={logo} alt="Logo" style={{height: '60px'}}/>
				</Navbar.Brand>
				
				
				<Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
					
					<div className="d-flex align-items-center">
						{userFirstName &&
							<Dropdown>
								<Dropdown.Toggle variant="success" id="dropdown-basic">
									<Navbar.Text className="mr-3 welcome">
										<i className="mdi mdi-account"></i>
										Welcome, {userFirstName}
									</Navbar.Text>
								
								</Dropdown.Toggle>
								<Dropdown.Menu>
									<Dropdown.Item as={Link} to="/edit-profile">Edit Profile</Dropdown.Item>
									<Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
								</Dropdown.Menu>
							</Dropdown>
						}
					
					</div>
				</Navbar.Collapse>
			</Navbar>
		</Container>
	);
}

export default TopBar;
