import React, {useState, useEffect} from 'react';
import {Table, Button, Pagination, Form, FormControl, Row, Col, Tooltip, OverlayTrigger} from 'react-bootstrap';
import './DynamicGrid.css';

const DynamicGrid = ({data, fields, actions}) => {
	const [fieldsArray, setFields] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [recordsPerPage, setRecordsPerPage] = useState(50);
	const [searchTerm, setSearchTerm] = useState('');
	const [sortField, setSortField] = useState('');
	const [sortDirection, setSortDirection] = useState('asc');
	
	const getActions = (typeof actions === 'function') ? actions : () => actions;
	const ActionsWidth = (fields.options && fields.options.actionsWidth) ? fields.options.actionsWidth : '14%';
	
	const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);
	const handlePrev = () => setCurrentPage(Math.max(1, currentPage - 1));
	const handleNext = () => setCurrentPage(Math.min(totalPages, currentPage + 1));
	
	const getNestedValue = (obj, path) => {
		// Check if the path contains a dot, indicating nested properties
		if (!path.includes('.')) {
			if (obj[path]) {
				if (parseInt(obj[path])) {
					return obj[path];
				} else {
					return obj[path].toString();
				}
			}
			return obj[path];
		}
		
		// Handle nested properties
		return path.split('.').reduce((acc, part) => {
			return acc && acc[part];
		}, obj);
	};
	
	const handleRecordsPerPageChange = (e) => {
		setRecordsPerPage(parseInt(e.target.value, 10));
		setCurrentPage(1);
	};
	const dataSize = data.length;
	
	const sortData = (field) => {
		const isAsc = (sortField === field && sortDirection === 'asc');
		setSortDirection(isAsc ? 'desc' : 'asc');
		setSortField(field);
	};
	
	const getSortIcon = (field) => {
		if (sortField !== field) return null;
		return sortDirection === 'asc' ? <i className="mdi mdi-arrow-up"></i> : <i className="mdi mdi-arrow-down"></i>;
	};
	
	const sortAndFilterData = () => {
		// Step 1: Filter the data based on the search term
		let filteredData = data.filter(item => {
			// If no search term is provided, return all items
			if (searchTerm === '') {
				return true;
			}
			
			// Check if any field contains the search term
			return fieldsArray.some(field => {
				let fieldValue = item[field.fieldName];
				// Convert the field value to a string if it's not null or undefined
				if (fieldValue) {
					fieldValue = fieldValue.toString().toLowerCase();
					return fieldValue.includes(searchTerm.toLowerCase());
				}
				return false;
			});
		});
		
		// Step 2: Sort the filtered data
		let sortedData = filteredData.sort((a, b) => {
			if (sortField) {
				let valA = getNestedValue(a, sortField);
				if (valA) {
					
					if (!parseInt(valA)) {
						valA = valA.toLowerCase();
					}
				}
				
				let valB = getNestedValue(b, sortField);
				if (valB) {
					if (!parseInt(valB)) {
						valB = valB.toLowerCase();
					}
				}
				
				if (valA < valB) return sortDirection === 'asc' ? -1 : 1;
				if (valA > valB) return sortDirection === 'asc' ? 1 : -1;
			}
			return 0;
		});
		
		return sortedData;
	};
	
	const sortedAndFilteredData = sortAndFilterData();
	const totalPages = Math.ceil(sortedAndFilteredData.length / recordsPerPage);
	
	// Calculate the data for the current page
	const paginatedData = sortedAndFilteredData.slice((currentPage - 1) * recordsPerPage, currentPage * recordsPerPage);
	
	const setOptions = () => {
		if (Array.isArray(fields)) {
			setFields(fields);
		} else {
			if (fields.data && Array.isArray(fields.data)) {
				setFields(fields.data);
			}
		}
	};
	
	useEffect(() => {
		setOptions();
	}, []);
	
	useEffect(() => {
		setCurrentPage(1);
	}, [searchTerm, recordsPerPage]);
	
	const renderPaginationItems = () => {
		let items = [];
		let startPage, endPage;
		
		if (totalPages <= 5) {
			startPage = 1;
			endPage = totalPages;
		} else {
			if (currentPage <= 3) {
				startPage = 1;
				endPage = 5;
			} else if (currentPage + 2 >= totalPages) {
				startPage = totalPages - 4;
				endPage = totalPages;
			} else {
				startPage = currentPage - 2;
				endPage = currentPage + 2;
			}
		}
		
		items.push(<Pagination.Prev key="prev" onClick={handlePrev} disabled={currentPage === 1}/>);
		if (startPage > 1) {
			items.push(<Pagination.Item key="1" onClick={() => handlePageChange(1)}>1</Pagination.Item>);
			if (startPage > 2) items.push(<Pagination.Ellipsis key="startEllipsis"/>);
		}
		for (let number = startPage; number <= endPage; number++) {
			items.push(
				<Pagination.Item key={number} active={number === currentPage} onClick={() => handlePageChange(number)}>
					{number}
				</Pagination.Item>
			);
		}
		if (endPage < totalPages) {
			if (endPage < totalPages - 1) items.push(<Pagination.Ellipsis key="endEllipsis"/>);
			items.push(<Pagination.Item key={totalPages}
			                            onClick={() => handlePageChange(totalPages)}>{totalPages}</Pagination.Item>);
		}
		items.push(<Pagination.Next key="next" onClick={handleNext} disabled={currentPage === totalPages}/>);
		
		return items;
	};
	
	const truncateText = (text, maxLength) => {
		if (!maxLength) {
			maxLength = 30;
		}
		if (text && (text.length > maxLength)) {
			if (text.length > maxLength + 3) {
				return text.substring(0, maxLength) + '..';
			} else {
				return text;
			}
			
		}
		return text;
	};
	
	return (
		<>
			<Row className="mb-3 justify-content-end">
				<Col md={6}>
					<FormControl
						type="search"
						placeholder="Search..."
						className="mr-2"
						aria-label="Search"
						onChange={(e) => setSearchTerm(e.target.value)}
					/>
				</Col>
			</Row>
			{!sortedAndFilteredData.length ? (
				<h3 className="text-center">No records found</h3>
			) : (
				<>
					<Table striped bordered hover className="table-smaller-rows">
						<thead>
						<tr>
							{fieldsArray.map((field, index) => (
								<th key={index}
								    onClick={() => sortData(field.fieldName)}
								    style={{
									    'cursor': 'pointer',
									    'width': field.width || 'auto' // Apply the width if provided
								    }}
								>
									{field.headerName} {getSortIcon(field.fieldName)}
								</th>
							))}
							{actions &&
								<th width={ActionsWidth}></th>}
						</tr>
						</thead>
						<tbody>
						{paginatedData.map((item, rowIndex) => (
							<tr key={rowIndex}>
								{fieldsArray.map((field, colIndex) => (
									<td key={`${rowIndex}-${colIndex}`} className={field.className}
									    title={getNestedValue(item, field.fieldName)}>
										
										{field.type === 'image' ?
											<img src={getNestedValue(item, field.fieldName)} alt="Preview" style={{ maxWidth: '50px' }} />
											:
											truncateText(getNestedValue(item, field.fieldName), field.fieldLength)
										}
										
									</td>
								))}
								{actions && (
									<td>
										{getActions(item).map((action, actionIndex) => (
											<OverlayTrigger
												key={actionIndex}
												placement="top"
												overlay={
													<Tooltip id={`tooltip-${actionIndex}`}>
														{action.label}
													</Tooltip>
												}
											>
												<Button
													variant={action.variant}
													onClick={() => action.action(item)}
													className="m-1 btn-sm btn-extra-sm"
												>
													{action.icon ? <i className={action.icon}></i> : action.label}
												</Button>
											</OverlayTrigger>
										))}
									
									</td>
								)}
							</tr>
						))}
						</tbody>
					</Table>
					
					{(dataSize > 20) ? (
						<div className="d-flex botton-pagination">
							<div className="col-sm-10 col-md-6">
								<Pagination>{renderPaginationItems()}</Pagination>
							</div>
							
							<div className="col-sm-10 col-md-6 d-flex align-items-center justify-content-end">
								<span className="mr-2">Display: &nbsp;</span>
								<Form.Group className="mb-0" style={{width: 'auto'}}>
									<Form.Control as="select" value={recordsPerPage}
									              onChange={handleRecordsPerPageChange}>
										<option value="20">20</option>
										<option value="50">50</option>
										<option value="100">100</option>
										<option value="200">200</option>
										<option value="500">500</option>
									</Form.Control>
								</Form.Group>
								
								<span className="mr-2"> per page of {sortedAndFilteredData.length} Records</span>
							</div>
						</div>
					) : (
						<div className="mr-2"> {sortedAndFilteredData.length} Record(s)</div>
					)}
				
				</>
			)}
		</>
	);
};

export default DynamicGrid;
