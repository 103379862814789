/*
 * Copyright (C) 2023 Corsair M360, Inc - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import React, {useState, useEffect, useContext} from 'react';
import utils from '../../../utils/api';
import {Container, Table, Button, Tooltip, OverlayTrigger} from 'react-bootstrap';

import {Link, useNavigate} from 'react-router-dom';
import {AlertContext} from '../../../components/AlertContext';
import {useBackdrop} from '../../../components/BackdropContext';

function ListPage() {
	const [sortField, setSortField] = useState('');
	const [sortDirection, setSortDirection] = useState('asc');
	
	const [healthList, setList] = useState([]);
	const [isLoading, setIsLoading] = useState(false); // State for loading
	const {setBackdropVisible} = useBackdrop();
	const navigate = useNavigate();
	const {showAlert} = useContext(AlertContext);
	
	const fetchDataList = async () => {
		setIsLoading(true); // Start loading
		try {
			const data = await utils.callApi({
				method: 'GET',
				endpoint: 'hmpboapi/category'
			}, navigate, showAlert, setBackdropVisible);
			if (data) {
				setList(data);
			}
		} catch (error) {
			console.error('Failed to fetch list:', error);
			// Handle errors appropriately
		} finally {
			setIsLoading(false); // Stop loading
		}
	};
	
	const handleRefresh = () => {
		fetchDataList();
	};
	
	useEffect(() => {
		fetchDataList();
	}, []);
	
	const handleEdit = (Id) => {
		navigate(`/edit-category/${Id}`);
	};
	
	const handleDelete = async (Id) => {
		const isConfirmed = window.confirm("Are you sure you want to delete this record?");
		
		if (isConfirmed) {
			try {
				let options = {
					endpoint: `hmpboapi/category/${Id}`,
					method: 'DELETE'
				};
				await utils.callApi(options, null, showAlert, setBackdropVisible);
				fetchDataList();
			} catch (error) {
				console.error('Failed to delete:', error);
			}
		}
	};
	
	const sortData = (field) => {
		const isAsc = (sortField === field && sortDirection === 'asc');
		setSortDirection(isAsc ? 'desc' : 'asc');
		setSortField(field);
	};
	
	const getSortIcon = (field) => {
		if (sortField !== field) return null;
		return sortDirection === 'asc' ?
			<i className="mdi mdi-arrow-up"></i> : <i className="mdi mdi-arrow-down"></i>;
	};
	
	const access = {
		delete: utils.checkAccess({method: 'DELETE', route: '/category/:id'}),
		update: utils.checkAccess({method: 'PATCH', route: '/category/:id'})
	};
	
	return (
		<Container>
			<h2>Category</h2>
			
			<div className="d-flex">
				<div className="col-sm-6">
					<Button onClick={handleRefresh} className="mb-3" title="Refresh List">
						<i className="mdi mdi-refresh"></i>
					</Button>
				</div>
				<div className="col-sm-6 d-flex align-items-center justify-content-end">
					<div className="d-flex justify-content-end">
						<Link to="/create-category" className="btn btn-secondary mb-3">
							Add New</Link>
					</div>
				</div>
			</div>
			
			{isLoading ? (
				<p>Loading...</p> // Display loading message
			) : (
				<div>
					<Table striped bordered hover className="table-smaller-rows">
						<thead>
						<tr>
							<th>Name</th>
							<th onClick={() => sortData('order')}
							    style={{
								    cursor: 'pointer',
							    }}>Order {getSortIcon('order')}</th>
							<th></th>
						</tr>
						
						</thead>
						<tbody>
						
						{healthList.map((item, index) => (
							<tr key={index}>
								<td>{item.name}
									<div className="ms-5">
										{Object.entries(item.sub).map(([key, value]) => (
											<div key={key}> - {value}</div>
										))}
									</div>
								</td>
								<td>{item.order}</td>
								<td>
									<OverlayTrigger
										overlay={<Tooltip>Edit</Tooltip>}>
										<Button
											variant="secondary"
											onClick={() => handleEdit(item._id)}
											className="m-1 btn-sm btn-extra-sm">
											<i className="mdi mdi-pencil"></i>
										</Button>
									</OverlayTrigger>
									
									{access.delete && (
										<OverlayTrigger
											overlay={<Tooltip>Delete</Tooltip>}>
											<Button
												variant="danger"
												onClick={() => handleDelete(item._id)}
												className="m-1 btn-sm btn-extra-sm">
												<i className="mdi mdi-delete"></i>
											</Button>
										</OverlayTrigger>
									)}
									
								</td>
							</tr>
						))}
						</tbody>
					</Table>
				</div>
			)}
			
		
		</Container>
	);
	
}

export default ListPage;
