/*
 * Copyright (C) 2021 Corsair M360, Inc - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import React, {useState, useEffect, useContext} from 'react'; // Import useState and useEffect
import {Navbar, Nav, Dropdown, Container, Row} from 'react-bootstrap';
import {Link} from 'react-router-dom';

function Footer() {
	
	useEffect(() => {
	
	}, []);
	
	
	return (
		<Row className="justify-content-md-center" id="footer-wrapper">
			
			American Botanical Council, 6200 Manor Rd, Austin, TX 78723
			Phone: 512-926-4900 | Fax: 512-926-2345 | Email: abc@herbalgram.org
		</Row>
	);
}

export default Footer;
