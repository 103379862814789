/*
 * Copyright (C) 2023 Corsair M360, Inc - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

// LoginPage.js
import React, {useState, useEffect, useContext} from 'react';
import {NavLink} from 'react-router-dom';
import withBasicLayout from '../../components/external/withBasicLayout';
import {Container, Col, Form, Button, Row} from 'react-bootstrap';
import utils from '../../utils/api';
import {useNavigate} from 'react-router-dom';
import {AlertContext} from '../../components/AlertContext';
import {useBackdrop} from '../../components/BackdropContext';

function LoginPage() {
	
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	
	const navigate = useNavigate();
	const {showAlert, hideAlert} = useContext(AlertContext);
	const {setBackdropVisible} = useBackdrop();
	
	const setUser = async () => {
		let options = {
			endpoint: 'user',
			method: 'GET',
		};
		try {
			const result = await utils.callApi(options, null, showAlert, setBackdropVisible, AlertContext);
			// localStorage.setItem('user', JSON.stringify(result.user));
			localStorage.setItem('acl', JSON.stringify(result.acl));
			navigate('/home');
		} catch (error) {
			console.error(error);
		}
	};
	
	const handleSubmit = async (event) => {
		event.preventDefault();
		let options = {
			endpoint: 'token',
			method: 'POST',
			data: {
				username,
				password
			}
		};
		try {
			hideAlert();
			const result = await utils.callApi(options, null, showAlert, setBackdropVisible, AlertContext);
			localStorage.setItem('user', JSON.stringify(result.user));
			localStorage.setItem('token', JSON.stringify(result.token));
			setUser();
		} catch (error) {
			console.error(error);
		}
	};
	
	return (
		<Container>
			<div className="justify-content-md-center">
				<Row>
					<Col md={4}>
						<div>
							<br/>
							<br/>
							<br/>
							<br/>
							<b>
								This dashboard is built exclusively for Herbmedpro compilers
							</b>
							
						</div>
						
					</Col>
					<Col md={1}></Col>
					<Col md={6}>
						<Form onSubmit={handleSubmit} className="mt-5 input-table">
							<h2 className="text-center">Login</h2>
							<Form.Group as={Row} controlId="formBasicUsername">
								<Form.Label>Username</Form.Label>
								<Form.Control
									type="text"
									placeholder="Enter username"
									value={username}
									onChange={(e) => setUsername(e.target.value)}
									required
								/>
							</Form.Group>
							<Form.Group as={Row} controlId="formBasicPassword">
								<Form.Label>Password</Form.Label>
								<Form.Control
									type="password"
									placeholder="Password"
									value={password}
									onChange={(e) => setPassword(e.target.value)}
									required
								/>
							</Form.Group>
							<br/>
							<Form.Group as={Row}>
								<Col md={6}>
									<Button variant="primary" type="submit" className="w-100">
										Login
									</Button>
								</Col>
								<Col md={6}>
									{/*<NavLink to="/forget-password">*/}
									{/*	Forgot password</NavLink>*/}
								</Col>
							</Form.Group>
						
						</Form>
					</Col>
				</Row>
			
			</div>
		</Container>
	);
}

export default withBasicLayout(LoginPage);
