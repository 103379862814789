/*
 * Copyright (C) 2023 Corsair M360, Inc - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import React, {useState, useEffect, useContext} from 'react';
import utils from '../../../utils/api';
import {Container, Button} from 'react-bootstrap';
import {Link, useNavigate} from 'react-router-dom';
import DynamicGrid from '../../../components/DynamicGrid';
import {AlertContext} from '../../../components/AlertContext';
import {useBackdrop} from '../../../components/BackdropContext';

function ListGenus() {
	const [genusList, setGenusList] = useState([]);
	const [isLoading, setIsLoading] = useState(false); // State for loading
	const navigate = useNavigate();
	const {showAlert} = useContext(AlertContext);
	const {setBackdropVisible} = useBackdrop();
	
	const fetchGenusList = async () => {
		setIsLoading(true); // Start loading
		try {
			const data = await utils.callApi({
				method: 'GET',
				endpoint: 'hmpboapi/genus'
			}, navigate, showAlert, setBackdropVisible);
			if (data) {
				setGenusList(data);
			}
		} catch (error) {
			console.error('Failed to fetch genus list:', error);
		} finally {
			setIsLoading(false); // Stop loading
		}
	};
	
	useEffect(() => {
		fetchGenusList();
	}, []);
	
	const handleEdit = (genusId) => {
		navigate(`/edit-genus/${genusId}`);
	};
	
	const handleDelete = async (genusId) => {
		const isConfirmed = window.confirm("Are you sure you want to delete this record?");
		
		if (isConfirmed) {
			let options = {
				'endpoint': `hmpboapi/genus/${genusId}`,
				method: 'DELETE'
			};
			try {
				await utils.callApi(options, null, showAlert, setBackdropVisible);
				fetchGenusList();
			} catch (error) {
				console.error('Failed to delete:', error);
			}
		}
	};
	
	const handleRefresh = () => {
		fetchGenusList();
	};
	
	const fields = [
		{headerName: 'Name', fieldName: 'name'},
		// Add other fields as necessary
	];
	
	const access = {
		delete: utils.checkAccess({method: 'DELETE', route: '/genus/:id'}),
		update: utils.checkAccess({method: 'PATCH', route: '/genus/:id'})
	};
	
	const actions = (item) => [
		(access.update) && {
			label: 'Edit', icon: 'mdi mdi-pencil',
			variant: 'secondary', action: (item) => handleEdit(item._id)
		},
		
		(access.delete) && {
			label: 'Delete', icon: 'mdi mdi-delete',
			variant: 'danger', action: (item) => handleDelete(item._id)
		}
	].filter(Boolean); // Remove false entries
	
	return (
		<Container>
			<h2>Genus</h2>
			
			<div className="d-flex">
				<div className="col-sm-6">
					<Button onClick={handleRefresh} className="mb-3" title="Refresh List">
						<i className="mdi mdi-refresh"></i>
					</Button>
				</div>
				<div className="col-sm-6 d-flex align-items-center justify-content-end">
					<div className="d-flex justify-content-end">
						<Link to="/create-genus" className="btn btn-secondary mb-3">
							Add New</Link>
					</div>
				</div>
			</div>
			{isLoading ? (
				<p>Loading...</p> // Display loading message
			) : (
				<DynamicGrid data={genusList} fields={fields} actions={actions}/>
			)}
		</Container>
	
	);
}

export default ListGenus;
