/*
 * Copyright (C) 2023 Corsair M360, Inc - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import React, {useState, useEffect, useContext} from 'react';
import utils from '../../../utils/api';
import {Container, Row, Col, Button, Form} from 'react-bootstrap';
import {Link, useNavigate, useParams} from 'react-router-dom';
import DynamicGrid from '../../../components/DynamicGrid';
import {AlertContext} from '../../../components/AlertContext';
import {useBackdrop} from '../../../components/BackdropContext';

function ListPage() {
	const [entryList, setList] = useState([]);
	const [herbName, setHerbName] = useState('');
	const [isLoading, setIsLoading] = useState(false); // State for loading
	const [categoryOptions, setCategoryOptions] = useState([]);
	const [sourceOptions, setSourceOptions] = useState([]);
	const [selectedCategory, setSelectedCategory] = useState('');
	const [selectedSource, setSelectedSource] = useState('');
	
	const navigate = useNavigate();
	const {showAlert} = useContext(AlertContext);
	const {setBackdropVisible} = useBackdrop();
	
	const {herbId} = useParams(); // Get the id param from the route
	
	const fetchCategories = async () => {
		try {
			let listCats = [];
			const categories = await utils.callApi({
				method: 'GET',
				endpoint: 'hmpboapi/category'
			}, null, showAlert);
			categories.forEach(function (one) {
				for (let k in one.sub) {
					listCats.push(
						{
							label: `${one.name} - ${one.sub[k]}`,
							name: one.sub[k]
						});
				}
				
			});
			setCategoryOptions(listCats);
		} catch (error) {
			console.error('Failed to fetch categories:', error);
		}
	};
	
	const fetchSource = async () => {
		try {
			const data = await utils.callApi({
				method: 'GET',
				endpoint: 'hmpboapi/source'
			}, null, showAlert);
			setSourceOptions(data);
		} catch (error) {
			console.error('Failed to fetch categories:', error);
		}
	};
	
	const fetchDataList = async () => {
		setIsLoading(true); // Start loading
		let endpoint = `hmpboapi/entry/list/${herbId}`;
		try {
			let options = {
				'method': 'GET',
				'endpoint': endpoint,
				'params': {}
			};
			
			if (selectedCategory) {
				options.params.category = selectedCategory;
			}
			if (selectedSource) {
				options.params.source = selectedSource;
			}
			const data = await utils.callApi(options, navigate, showAlert, setBackdropVisible);
			if (data) {
				data.forEach(function (one) {
					if (one.approved) {
						one.approved = 'Yes';
					} else {
						one.approved = 'No';
					}
				});
				setList(data);
			}
		} catch (error) {
			console.error('Failed to fetch data:', error);
		} finally {
			setIsLoading(false); // Stop loading
		}
	};
	
	const getHerb = async () => {
		try {
			const herb = await utils.callApi({
				endpoint: `hmpboapi/herb/${herbId}`
			}, navigate, showAlert, setBackdropVisible);
			if (herb) {
				setHerbName(herb.scientificName);
			}
		} catch (error) {
			console.error('Failed to fetch herb:', error);
			navigate('/herbs');
		}
	};
	
	const handleRefresh = () => {
		fetchDataList();
	};
	
	const access = {
		delete: utils.checkAccess({method: 'DELETE', route: '/entry/:id'}),
		approve: utils.checkAccess({method: 'PATCH', route: '/entry/approve/:id'}),
		update: utils.checkAccess({method: 'PATCH', route: '/entry/:id'})
	};
	
	useEffect(() => {
		if (herbId) {
			getHerb();
		} else {
			console.error('Missing herb');
		}
		fetchSource();
		fetchCategories();
		
	}, []);
	
	useEffect(() => {
		if (herbId) {
			fetchDataList();
		} else {
			console.error('Missing herb');
		}
	}, [selectedCategory, selectedSource]);
	
	
	const handleEdit = (Id) => {
		navigate(`/edit-entry/${Id}`);
	};
	
	const handleDelete = async (Id) => {
		const isConfirmed = window.confirm("Are you sure you want to delete this record?");
		let options = {
			endpoint: `hmpboapi/entry/${Id}`,
			method: 'DELETE'
		};
		if (isConfirmed) {
			try {
				await utils.callApi(options, null, showAlert, setBackdropVisible);
				fetchDataList();
			} catch (error) {
				console.error('Failed to delete:', error);
			}
		}
		
	};
	
	const handleUnPublish = async (Id) => {
		const isConfirmed = window.confirm("Are you sure you want to unPublish this record?");
		let options = {
			endpoint: `hmpboapi/entry/approve/${Id}`,
			method: 'PATCH',
			data: {
				approved: false
			}
		};
		if (isConfirmed) {
			try {
				await utils.callApi(options, null, showAlert, setBackdropVisible);
				fetchDataList();
			} catch (error) {
				console.error('Failed to edit:', error);
			}
		}
		
	};
	
	const handlePublish = async (Id) => {
		let options = {
			endpoint: `hmpboapi/entry/approve/${Id}`,
			method: 'PATCH',
			data: {
				approved: true
			}
		};
		
		try {
			await utils.callApi(options, null, showAlert, setBackdropVisible);
			fetchDataList();
		} catch (error) {
			console.error('Failed to edit:', error);
		}
	};
	
	const fields = {
		options: {
			actionsWidth: '12%'
		},
		data: [
			{headerName: 'Live', width: '5%', fieldName: 'approved'},
			//{headerName: 'Source', fieldName: 'source'},
			{
				headerName: 'Link Caption',
				fieldLength: 35,
				fieldName: 'linkCaption', className: "wordBreak"
			},
			{
				headerName: 'Link Url',
				width: '15%',
				fieldName: 'linkUrl',
				fieldLength: 30,
				className: "allBreak"
			},
			//{headerName: 'PubMedID', fieldName: 'PubMedID'},
			{headerName: 'Category', fieldName: 'category.name'},
			{
				headerName: 'Date Created',
				fieldLength: 18,
				width: '15%', fieldName: 'created',
				className: "allBreak"
			},
			{
				headerName: 'Last Edited',
				fieldLength: 18,
				width: '15%', fieldName: 'modified', className: "allBreak"
			},
			{headerName: 'Created By', fieldName: 'author.name'}
		]
	};
	
	const actions = (item) => [
		(access.update) && {
			label: 'Edit', icon: 'mdi mdi-pencil',
			variant: 'secondary',
			action: (item) => handleEdit(item._id)
		},
		(access.approve && item.approved === 'No') && {
			label: 'Approve',
			icon: 'mdi mdi-cloud-upload', variant: 'primary',
			action: (item) => handlePublish(item._id)
		},
		(access.approve && item.approved === 'Yes') && {
			label: 'Un-Approve',
			icon: 'mdi mdi-cloud-upload', variant: 'danger',
			action: (item) => handleUnPublish(item._id)
		},
		(access.delete) && {
			label: 'Delete', icon: 'mdi mdi-delete', variant: 'danger',
			action: (item) => handleDelete(item._id)
		}
	].filter(Boolean); // Remove false entries
	
	return (
		<Container>
			<h2>
				Entries {herbName &&
				<span> for <Link to={`/edit-herb/${herbId}`} className="">
					"{herbName}"</Link> </span>
			}
			</h2>
			
			
			<div className="d-flex">
				<div className="col-sm-6">
					<Button onClick={handleRefresh} className="mb-3" title="Refresh List">
						<i className="mdi mdi-refresh"></i>
					</Button>
				</div>
				<div className="col-sm-6 d-flex align-items-center justify-content-end">
					<div className="d-flex justify-content-end">
						<Link to={`/add-entry/${herbId}`} className="btn btn-secondary mb-3">
							Add New</Link>
					</div>
				</div>
			</div>
			
			<div>
				<Row>
					<Col md={1}>
						Category
					</Col>
					<Col md={3}>
						<Form.Select aria-label="Select Category" onChange={e => setSelectedCategory(e.target.value)}>
							<option value="">Select Category</option>
							{categoryOptions.map((category, index) => (
								<option key={index} value={category.name}>{category.label}</option>
							))}
						</Form.Select>
					</Col>
					<Col md={1}>
					
					</Col>
					<Col md={1}>
						Source
					</Col>
					<Col md={3}>
						<Form.Select aria-label="Select Source" onChange={e => setSelectedSource(e.target.value)}>
							<option value="">Select Source</option>
							{sourceOptions.map((category, index) => (
								<option key={index} value={category.name}>
									{category.name}</option>
							))}
						</Form.Select>
					</Col>
				</Row>
				<br/>
			</div>
			{isLoading ? (
				<p>Loading...</p> // Display loading message
			) : (
				<DynamicGrid data={entryList} fields={fields} actions={actions}/>
			)}
		
		</Container>
	
	);
	
}

export default ListPage;